import React, { Component } from 'react'
import Header from './Header'
import DrawerLeft from './DrawerLeft'
import { handleResponse, post } from '../../utils/network';
import withStyles from "@material-ui/core/styles/withStyles";
import { connect } from 'react-redux';

const styles = theme => ({
    childrenContainer: {
        margin: "20px 40px",
        [theme.breakpoints.down("md")]: {
            margin: 20
        },
        [theme.breakpoints.down("xs")]: {
            margin: "20px 0"
        }
    },
    layout: {
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 2
    }
});

class Layout extends Component {
    constructor(props) {
        super(props);
        this.state = {
            clubFullName: '',
            mobileOpen: false
        }
    }

    componentDidMount() {
        const { club } = this.props;
        if (club) {
            this.setState({ clubFullName: club.fullName });
        } else {
            this.props.history.push('/onboarding');
        }
    };

    handleDrawerToggle = (value) => {
        if (value !== undefined) {
            this.setState({ mobileOpen: value });
        } else {
            this.setState(prevState => ({ mobileOpen: !prevState.mobileOpen }));
        }
    };

    logout = () => {
        post('auth/logout', "")
            .then(res => {
                return handleResponse(res, this.props);
            })
            .then(data => {
                this.props.history.push("/login");
            })
    };

    render() {
        const { classes } = this.props;
        return (
            <div style={{ display: 'flex' }}>
                <DrawerLeft
                    history={this.props.history}
                    mobileOpen={this.state.mobileOpen}
                    handleDrawerToggle={this.handleDrawerToggle}
                />
                <div className={classes.layout}>
                    <Header
                        title={this.state.clubFullName}
                        handleDrawerToggle={this.handleDrawerToggle}
                        logout={this.logout}
                        handleProfileClick={this.handleProfileClick}
                    />
                    <div className={classes.childrenContainer}>
                     {this.props.children}
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    club: state
});

export default connect(mapStateToProps)(withStyles(styles)(Layout));
