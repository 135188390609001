export default (values) => {
    let errors = {};

    if (!values.question) {
        errors.question = "Complete la pregunta";
    }

    if (!values.answer) {
        errors.answer = "Complete la respuesta";
    }

    return errors;
}