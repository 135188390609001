import { POSITIVE_INT_REGEX, validateAgeFields } from "../../../utils/utils";

export const ADDRESS = /.+(,.+){1,2}/;
export const NAME_REGEX = /^[\p{L}\d -]+$/ui;
const POSITIVE_NUMBER_REGEX = /^\d+([.]\d+)?$/;
const TIME_OF_DAY_24_HOURS_REGEX = /^([01][0-9]|2[0-3]):([0-5][0-9])$/;

export default (values) => {
    let errors = {};

    if (!values.name) {
        errors.name = "Complete el nombre";
    } else if (!NAME_REGEX.test(values.name)) {
        errors.name = "El nombre debe ser un nombre válido"
    }

    if (!values.description) {
        errors.description = "Complete la descripción";
    }

    if(typeof values.detailImage == "object" && !values.detailImage.type.includes("image/"))
    {
        errors.detailImage = "Debe cargar una imagen."
    } 

    if(Array.isArray(values.schedules)) {

        if(values.schedules.length === 0) 
            errors.schedules = "Defina al menos un horario."
    }

    if(values.newStartTime)
    {
        if (!TIME_OF_DAY_24_HOURS_REGEX.test(values.newStartTime)) {
            errors.newStartTime = "El horario debe estar en formato 24 horas."
        }
    }

    if(values.newEndTime)
    {
        if (!TIME_OF_DAY_24_HOURS_REGEX.test(values.newEndTime)) {
            errors.newEndTime = "El horario debe estar en formato 24 horas."
        }
    }

    if (!values.price) {
        errors.price = "Complete el precio";
    } else if (!POSITIVE_NUMBER_REGEX.test(values.price)) {
        errors.price = "El precio debe ser un número no negativo."
    }

    if (!values.lifelongPrice) {
        errors.lifelongPrice = "Complete el precio para vitalicios";
    } else if (!POSITIVE_NUMBER_REGEX.test(values.lifelongPrice)) {
        errors.lifelongPrice = "El precio para vitalicios debe ser un número no negativo."
    }

    validateAgeFields(values, errors);

    if(!values.discipline)
    {
        errors.discipline = "Seleccione una disciplina asociada."
    }

    if (values.enrollmentSlots && !POSITIVE_INT_REGEX.test(values.enrollmentSlots)) {
        errors.enrollmentSlots = "La cantidad máxima de inscriptos debe ser un número no negativo."
    }
    
    return errors;
}