import React, { useState, useEffect } from 'react';
import { handleResponse, post, patch, swal500, del } from '../../../utils/network';
import { useFormValidation } from "../../common";
import validateFAQs from './validator';
import FAQsFormComponent from "./FAQsFormComponent";
import * as Swal from "sweetalert2";

export const FIELDS = {
    question: "Pregunta",
    answer: "Respuesta"
};

const INITIAL_STATE = {
    question: '',
    answer: ''
};

const FAQsFormContainer = (props) => {
    const [data, setData] = useState({});
    const [editMode, setEditMode] = useState(props.location.editMode || false);
    const [loading, setLoading] = useState(true);
    const [submitting, setSubmitting] = useState(false);
    const isNewFAQ = Object.entries(data).length === 0;

    const {
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        errors,
        setErrors,
        resetValues
    } = useFormValidation(INITIAL_STATE, values => validateFAQs(values, isNewFAQ));

    const getChangedValues = values => {
        const changedValues = [];
        Object.keys(values).forEach(key => {
            data[key] !== values[key] && changedValues.push(key)
        });
        return changedValues.filter(v => v !== 'created');
    };

    const handle400 = err => {
        err.text().then(error => {
            let errorObject = JSON.parse(error);
            let errors = {};
            errorObject.fields.forEach(field => {
                const regex = new RegExp(field.name);
                errors[field.name] = field.message.replace(regex, FIELDS[field.name]);
            });
            setErrors(errors);
        });
        return Promise.reject(400);
    };

    useEffect(() => {
        if (props.match.params.id === 'new') {
            setEditMode(true);
            setLoading(false);
        } else if (props.location.state) {
            const { id, question, answer, author } = props.location.state;

            if (id && question && answer && author) {
                const newData = { _id: id, question, answer, author }
                setData(newData)
                resetValues(newData);
                setLoading(false);
            }
        }
    }, [props, resetValues]);

    const handleClose = () => props.history.push('/home/faqs');

    const handleDelete = () => {
        Swal.fire({
            title: "Eliminar Pregunta Frecuente",
            text: `¿Estas seguro de que queres eliminar la Pregunta Frecuente ${values.question}?`,
            icon: 'warning',
            showCancelButton: true,
            cancelButtonColor: '#4eaaf3', //TODO: theme.palette.primary.main
            confirmButtonColor: "#FF0000", //TODO: theme.palette.state.failure
            cancelButtonText: 'No, conservar Pregunta Frecuente',
            confirmButtonText: 'Si, estoy seguro',
            showLoaderOnConfirm: true,
            preConfirm: () => {
                Swal.update({ showCancelButton: false })
                return del(`faqs/${props.match.params.id}`)
                    .then(res => handleResponse(res, props))
                    .then(result =>
                        props.history.push({
                            pathname: '/home/faqs',
                            state: { msg: 'deleted' },
                        })
                    )
                    .catch(err => {
                        swal500(err);
                    });
            }
        });
    };

    const handleResult = (e) => {
        const formValid = handleSubmit(e);
        if (formValid) {
            setSubmitting(true);
            if (isNewFAQ) {
                post('faqs', {
                    question: values.question,
                    answer: values.answer,
                })
                    .then(res => handleResponse(res, props, [{ status: 400, method: handle400 }]))
                    .then(res => {
                        setSubmitting(false);
                        props.history.push({
                            pathname: '/home/faqs',
                            state: { msg: 'created' },
                        });
                    })
                    .catch(err => {
                        setSubmitting(false);
                        if (err !== 400) {
                            swal500(err);
                        }
                    });
            } else {
                const body = {};
                const changedValues = getChangedValues(values);
                if (changedValues.length === 0) {
                    props.history.push('/home/faqs');
                    return;
                }
                changedValues.forEach(value => {
                    Object.assign(body, { [value]: values[value] });
                });
                patch(`faqs/${data._id}`, body)
                    .then(res => handleResponse(res, props, [{ status: 400, method: handle400 }]))
                    .then(res => {
                        setSubmitting(false);
                        props.history.push({
                            pathname: '/home/faqs',
                            state: { msg: 'edited' },
                        });
                    })
                    .catch(err => {
                        setSubmitting(false);
                        if (err !== 400) {
                            swal500(err);
                        }
                    });
            }
        }
    };

    return (
        <FAQsFormComponent
            errors={errors}
            handleChange={handleChange}
            handleBlur={handleBlur}
            handleClose={handleClose}
            handleResult={handleResult}
            handleDelete={handleDelete}
            loading={loading}
            submitting={submitting}
            values={values}
            editMode={editMode}
            setEditMode={setEditMode}
            isNewFAQ={isNewFAQ}
        />
    );
};

export default FAQsFormContainer;