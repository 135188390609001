import { useState } from "react";

const usePermissionsSelector = () => {
    const [permissions, setPermissions] = useState([]);

    const checkPermission = (permissions, name, checked) => {
        return permissions.map(permission => {
            if (permission.name === name) {
                return { ...permission, checked: checked };
            }
            return permission;
        });
    };

    const handleChangePermission = categoryName => e => {
        const { name, checked } = e.target;
        setPermissions(prevState =>
            prevState.map(category => {
                if (category.category === categoryName) {
                    return {
                        ...category,
                        permissions: checkPermission(category.permissions, name, checked)
                    };
                }
                return category;
            })
        );
    };

    const handleChangeCategory = categoryName => e => {
        setPermissions(prevState =>
            prevState.map(category => {
                if (category.category === categoryName) {
                    return {
                        ...category,
                        permissions: category.permissions.map(p => ({
                            ...p,
                            checked: (!e.target.checked && p.disabled) ? true : e.target.checked //if permission is disabled, cant uncheck
                        }))
                    };
                }
                return category;
            })
        );
    };

    const getPermissionsList = permissions
        .flatMap(category => category.permissions)
        .filter(permission => permission.checked && !permission.disabled)
        .map(permission => permission._id);

    const permissionsDidChange = oldPermissions => {
        if (getPermissionsList.length !== oldPermissions.length) return true;
        else {
            getPermissionsList.forEach(permission => {
                if (!oldPermissions.includes(permission)) return true;
            });
        }
        return false; //permissions didnt change
    };

    return {
        handleChangePermission,
        handleChangeCategory,
        permissions,
        setPermissions,
        getPermissionsList,
        permissionsDidChange
    }
};

export default usePermissionsSelector;