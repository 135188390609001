export default [
    {
        id: 'name',
        label: 'Nombre',
        minWidth: 100,
        hidden: {
            xsDown: true
        }
    },
    {
        id: 'isCompetitive',
        label: 'Competitiva',
        minWidth: 100,
        hidden: {
            xsDown: true
        }
    }
];
