import React, { useState } from 'react';
import { TextField, InputAdornment, IconButton } from '@material-ui/core';
import { Visibility, VisibilityOff, VpnKey as VpnKeyIcon } from '@material-ui/icons';

export default function CustomPassword(props) {
    const [showPassword, setShowPassword] = useState(false);
    return (
        <TextField
            error={Boolean(props.error)}
            name={props.name}
            value={props.value}
            onChange={props.handleChange}
            margin="normal"
            variant={props.variant}
            type={showPassword ? 'text' : 'password'}
            fullWidth
            helperText={props.error}
            label={props.label}
            placeholder={props.placeholder}
            onBlur={props.onBlur}
            className={props.className}
            InputProps={{
                ...props.InputProps,
                startAdornment: !props.hideStartAdornment &&
                    <InputAdornment position="start">
                        <VpnKeyIcon style={{ marginRight: 5, color: "grey" }} />
                    </InputAdornment>,
                endAdornment:
                    <InputAdornment position='end'>
                        <IconButton
                            onClick={() => setShowPassword(!showPassword)}
                            onMouseDown={e => e.preventDefault()}
                        >
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                    </InputAdornment>
            }}
        />
    );
}
