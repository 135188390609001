export const EMAIL_REGEX = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const PASSWORD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])/;
export const NAME_REGEX = /^([a-zA-ZÀ-úÜü]+\s?)+$/;
const POSITIVE_INT_REGEX = /^\d+$/;

export default (values) => {
    let errors = {};

    if (!values.firstName) {
        errors.firstName = "Complete el nombre";
    } else if (!NAME_REGEX.test(values.firstName)) {
        errors.firstName = "El nombre debe ser un nombre válido"
    }


    if (!values.lastName) {
        errors.lastName = "Complete el apellido";
    } else if (!NAME_REGEX.test(values.lastName)) {
        errors.lastName = "El apellido debe ser un apellido válido"
    }

    if (!values.email) {
        errors.email = "Complete el correo electrónico";
    } else if (!EMAIL_REGEX.test(values.email)) {
        errors.email = "El correo electrónico debe ser un correo electrónico válido";
    }

    if (values.phoneNumber && !POSITIVE_INT_REGEX.test(values.phoneNumber)) {
        errors.phoneNumber = "El teléfono debe ser un número entero positivo";
    }

    return errors;
}