import { getOpenInterval } from '../utils'

export default (values, facility) => {
    let errors = {};

    if (!values.memberNumber && !values.reservor?.memberNumber) {
        errors.memberNumber = "Complete el número de socio";
    }

    if (!getOpenInterval(values.start, facility)) {
        errors.start = "No está abierta la instalación durante la duración completa de la reserva";
    }
    
    return errors;
}