import React, { useState, useEffect } from 'react';
import { handleResponse, patch, swal500, get } from '../../../utils/network';
import { useFormValidation } from "../../common";
import validateReservation from './validator';
import ReservationsFormComponent from "./ReservationsFormComponent";
import moment from 'moment';
import 'moment/locale/es';

export const FIELDS = {
    facility: "Instalación",
    shiftDuration: 'Duración del turno',
    shiftPrice: 'Precio del turno',
    memberNumber: "Número de socio",
    firstName: "Nombre",
    lastName: "Apellido",
    email: 'Correo electrónico',
    start: 'Desde',
    end: 'Hasta'
};

const INITIAL_STATE = {
    memberNumber: null,
    reservor: null,
    start: null,
    end: null
};

const ReservationsFormContainer = (props) => {
    const { facility, reservation, start, handleClose, handleDelete, setMessage, addReservation } = props;
    const [submitting, setSubmitting] = useState(false);
    const isNewReservation = Object.entries(reservation).length === 0;

    const {
        handleSubmit,
        handleChange,
        handleChanges,
        handleCheckBox,
        handleDateChange,
        handleBlur,
        values,
        errors,
        setErrors,
        mergeErrors,
        resetValues
    } = useFormValidation(INITIAL_STATE, values => validateReservation(values, facility));

    useEffect(() => {
        if (isNewReservation) {
            resetValues({
                start: moment(start),
                end: moment(start).add(60, 'minutes')
            });
        }
        else {
            resetValues(reservation);
        }
    }, [reservation, start, isNewReservation, props, resetValues])

    const handle400 = err => {
        err.text().then(error => {
            let errorObject = JSON.parse(error);
            let errors = {};
            errorObject.fields.forEach(field => {
                const name = field.name;
                const regex = new RegExp(name);
                const internalName = name === 'user' ? 'memberNumber' : name === 'reservation' ? 'start' : name;
                errors[internalName] = field.message.replace(regex, FIELDS[name]);
            });
            setErrors(errors);
        });
        return Promise.reject(400);
    };

    const memberNumberValue = values.memberNumber;

    useEffect(() => {
        const fetchMemberData = (memberNumber) => {
            setSubmitting(true);
            get(`users?page=0&memberNumber=eq:${memberNumber}`)
                .then(res => handleResponse(res, props))
                .then(res => {
                    setSubmitting(false);
                    const responseUser = res.message.data;
                    if (responseUser.length) {
                        handleChanges({ reservor: responseUser[0] })
                    }
                    else {
                        mergeErrors({ memberNumber: "No existe un socio con ese número" })
                        handleChanges({ reservor: null });
                    }
                })
                .catch(err => {
                    setSubmitting(false);
                    if (err !== 400) {
                        return swal500(err);
                    }

                    setErrors((prevstate) => ({ ...prevstate, "memberNumber": "No se encontro a nadie con ese número de socio!" }))
                });
        }

        if (memberNumberValue) {
            const typeTimeout = setTimeout(() => fetchMemberData(memberNumberValue), 1000)

            return () => clearTimeout(typeTimeout)
        }
    }, [memberNumberValue])

    const handleResult = (e) => {
        const formValid = handleSubmit(e);
        if (formValid) {
            setSubmitting(true);
            patch(`facilities/${facility._id}/reserve`, {
                user_id: values.reservor._id,
                start: values.start
            })
                .then(res => handleResponse(res, props, [{ status: 400, method: handle400 }]))
                .then(res => {
                    setSubmitting(false);
                    const start = new Date(res.message.start);
                    const end = new Date(res.message.end);
                    addReservation({ ...res.message, start, end, reservor: values.reservor });
                    setMessage('created');
                    handleClose();
                })
                .catch(err => {
                    setSubmitting(false);
                    if (err !== 400) {
                        swal500(err);
                    }
                });
        }
    };

    return (
        <ReservationsFormComponent
            errors={errors}
            handleChange={handleChange}
            handleCheckBox={handleCheckBox}
            handleBlur={handleBlur}
            handleClose={handleClose}
            handleDateChange={handleDateChange}
            handleResult={handleResult}
            handleDelete={() => handleDelete(reservation._id)}
            loading={false}
            submitting={submitting}
            values={values}
            facility={props.facility}
            isNewReservation={isNewReservation}
        />
    );
};

export default ReservationsFormContainer;