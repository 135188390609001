import React, { Component } from 'react';
import { withTheme, } from "@material-ui/core";
import { put, handleResponse, swal500 } from '../../../utils/network';
import PasswordResetFormComponent from "./PasswordResetFormComponent";
import { connect } from 'react-redux';

const FIELDS = {
    password: 'contraseña',
    passwordRepeat: "confirmación de contraseña"
};

class PasswordResetFormContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            password: '',
            passwordRepeat: '',
            errorpassword: '',
            errorpasswordRepeat: '',
            errortoken: '',
            loading: false,
            success: false
        }
    }

    errorReset = () => {
        this.setState({
            errorpassword: '',
            errorpasswordRepeat: ''
        });
    };

    handleChange = e => {
        const { name, value } = e.target;
        this.errorReset();
        this.setState({ [name]: value });
    };

    checkLength = pass => pass && pass.length >= 8 && pass.length <= 20;

    checkRegex = pass => /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])/.test(pass);

    checkNoErrors = () => {
        let returnValue;
        if (this.checkLength(this.state.password)) {
            if (this.checkRegex(this.state.password)) {
                returnValue = true;
            } else {
                this.setState({
                    errorpassword: 'La contraseña debe tener al menos un caracter en minúscula, uno en mayúscula y un número.'
                });
                returnValue = false;
            }
        } else {
            this.setState({ errorpassword: 'La contraseña debe tener entre 8 y 20 caracteres.' });
            returnValue = false;
        }

        if (this.state.password === this.state.passwordRepeat) {
            return returnValue;
        } else {
            this.setState({ errorpasswordRepeat: 'Las contraseñas deben coincidir.' });
            return false;
        }
    };

    handle400 = err => {
        err.text().then(error => {
            let errorObject = JSON.parse(error);
            errorObject.fields.forEach(field => {
                if (field.name === 'token') this.setState({ errortoken: 'Enlace invalido. Solicite el cambio de contraseña nuevamente.' });
                else {
                    const message = field.message.replace(new RegExp('password'), FIELDS['password'])
                    this.setState({ ['error' + field.name]: message });
                }
            });
        });
        return Promise.reject(400);
    };


    handleSubmit = (e) => {
        e.preventDefault();
        const token = this.props.location.search.slice(6);//?hash=asd123
        if (this.checkNoErrors()) {
            this.setState({ loading: true }, () => {
                const { clubShortName } = this.props;
                const body = { password: this.state.password, token, clubShortName };
                put('auth/passwordreset', body, false)
                    .then(res => {
                        return handleResponse(res, this.props, [
                            { status: 400, method: this.handle400 },
                            { status: 401, method: this.handle400 }
                        ]);
                    })
                    .then(data => {
                        this.setState({ loading: false }, () => {
                            this.setState({ success: true });
                        });
                    })
                    .catch(err => {
                        this.setState({ loading: false }, () => {
                            if (err !== 400) {
                                swal500(err);
                            }
                        });
                    });
            });
        }
    };

    handleSuccess = () => this.props.history.push("/");

    render() {
        return (
            <PasswordResetFormComponent
                data={this.state}
                handleSubmit={this.handleSubmit}
                handleChange={this.handleChange}
                handleSuccess={this.handleSuccess}
                onDismissError={() => this.setState({ errortoken: '' })}
            />
        )
    }
}

const mapStateToProps = ({ shortName: clubShortName }) => ({ clubShortName })

export default connect(mapStateToProps)(withTheme(PasswordResetFormContainer));