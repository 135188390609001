import React, { Component } from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Card, Grid, TextField, withStyles, withTheme } from '@material-ui/core';
import styles from './styles'
import { ExpandMore } from '@material-ui/icons';

class ReportsContainer extends Component {

    render() {
        const { classes } = this.props;
        return (
            <div>
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMore />}
                    >
                        <h2 className={classes.title}>Reportes de Estado de Cuenta</h2>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Grid
                            container
                            direction="column"
                            alignItems="center"
                            justify="center"
                        >
                            <iframe
                                src="https://dashboards.clubify.cf/public/question/36152160-d93c-4af3-a3d5-2e035c5c4430"
                                frameborder="0"
                                width="800"
                                height="600"
                                allowtransparency
                            ></iframe>
                            <div className={classes.divider} />
                            <iframe
                                src="https://dashboards.clubify.cf/public/question/430b1d21-71f6-4ff7-a12c-353af44a121d"
                                frameborder="0"
                                width="800"
                                height="600"
                                allowtransparency
                            ></iframe>
                        </Grid>
                    </AccordionDetails>
                </Accordion>
                <div className={classes.divider} />
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMore />}
                    >
                        <h2 className={classes.title}>Reportes de Instalaciones</h2>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Grid
                            container
                            direction="column"
                            alignItems="center"
                            justify="center"
                        >
                            <iframe
                                src="https://dashboards.clubify.cf/public/question/dc21cea6-d7f0-4cd5-8a99-7a4a8e47ee30"
                                frameborder="0"
                                width="800"
                                height="600"
                                allowtransparency
                            ></iframe>
                            <div className={classes.divider} />
                            <iframe
                                src="https://dashboards.clubify.cf/public/question/c0b10d25-06ae-4d03-8bd5-afd8df83af9f"
                                frameborder="0"
                                width="800"
                                height="600"
                                allowtransparency
                            ></iframe>

                        </Grid>
                    </AccordionDetails>
                </Accordion>
                <div className={classes.divider} />
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMore />}
                    >
                        <h2 className={classes.title}>Reportes de Actividades</h2>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Grid
                            container
                            direction="column"
                            alignItems="center"
                            justify="center"
                        >
                            <iframe
                                src="https://dashboards.clubify.cf/public/question/419d1d40-8483-4a2b-b63f-89dea5a04531"
                                frameborder="0"
                                width="800"
                                height="600"
                                allowtransparency
                            ></iframe>
                            <div className={classes.divider} />
                            <iframe
                                src="https://dashboards.clubify.cf/public/question/dc066617-0fb7-4d39-a460-44c4a44bec02"
                                frameborder="0"
                                width="800"
                                height="600"
                                allowtransparency
                            ></iframe>
                        </Grid>
                    </AccordionDetails>
                </Accordion>
            </div>
        )
    }
}

export default withTheme(withStyles(styles)(ReportsContainer));
