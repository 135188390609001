import React, { Component } from 'react';
import { withTheme, withStyles, Checkbox, FormControlLabel } from '@material-ui/core';
import { SmsFailed, Search } from '@material-ui/icons';
import EqualizerIcon from '@material-ui/icons/Equalizer';
import { Card, TextField, Grid, IconButton } from '@material-ui/core';
import * as Swal from 'sweetalert2';
import { handleResponse, get, del, swal500 } from '../../../utils/network';
import { TableComponent } from "../../common";
import COLUMNS from "./columns";
import moment from 'moment';
import 'moment/locale/es';

const styles = theme => ({
    header: {
        padding: "5px 5px 5px 5px",
        marginBottom: "20px",
        paddingLeft: "20px",
        paddingRight: "20px",
        fontSize: "20px",
        color: "#808080"
    },
    dateInput: {
        height: "100%",
        display: "inline-flex",
        flexDirection: "row"
    },
    searchInput: {
        height: "100%",
        display: "inline-flex",
        flexDirection: "row",
        marginBottom: "6px"
    },
    searchButton: {
        marginTop: "10px"
    },
    bodyContainer: {
        marginTop: "10px"
    },
    textField: {
        marginTop: 13,
        marginLeft: "15px"
    },
    dateField: {
        marginTop: 13,
        marginLeft: "15px",
        marginBottom: "26px"
    },
    loadMoreButton: {
        width: "100%",
        display: "inline-flex",
        marginTop: "30px",
        justifyContent: "center"
    },
    noResults: {
        textAlign: "center",
        fontSize: "20px",
        marginTop: "30px",
        color: theme.palette.secondary.main
    }
});

const MESSAGES = {
    created: 'La novedad ha sido creada correctamente',
    edited: 'La novedad ha sido editada correctamente',
    deleted: 'La novedad ha sido eliminada correctamente'
};

class NewsTableContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            page: 0,
            maxPageFetched: 0,
            editing: false,
            news: [],
            newsCount: 0,
            loading: true,
            updating: false,
            successMessage: '',
            titleFilter: '',
            importantFilter: false,
            onlyMineFilter: false,
            dateFilter: false,
            startDate: moment().subtract(1, "months"),
            endDate: moment(),
        }
    }

    componentDidMount() {
        this.getNews();
        const msgKey = this.props.location.state?.msg;
        if (msgKey) {
            const msg = MESSAGES[msgKey];
            msg && this.setState({ successMessage: msg });
        }
    };

    formatNews = (news) => {
        return news.map(news => {
            const newsCopy = { ...news };
            newsCopy.createDate = moment(newsCopy.createDate).format('DD/MM/YYYY');
            newsCopy.readCount = (<Grid
                container
                direction="row"
                justify="center"
                alignItems="center"
            >{newsCopy.readCount}
                <EqualizerIcon style={{ color: 'grey', marginLeft: 10 }} /></Grid>);
            newsCopy.important = newsCopy.important && (<SmsFailed style={{ color: 'grey', marginLeft: 10 }} />);
            return newsCopy;
        })
    }

    getFetchUrl = (page) => {
        const filters = [];
        if (this.state.titleFilter !== '') {
            filters.push(`title=like:${this.state.titleFilter}`);
        }
        if (this.state.importantFilter)
            filters.push("important=eqb:true")
        if (this.state.onlyMineFilter)
            filters.push("onlyMine=eqb:true")
        if (this.state.dateFilter)
            filters.push(`from=${this.state.startDate.toISOString()}`, `to=${this.state.endDate.toISOString()}`);
        const queryParams = ['app=bof', `page=${page}`, ...filters];
        return `news?${queryParams.join('&')}`
    }

    getNews = () => {
        const fetchUrl = this.getFetchUrl(0);
        get(fetchUrl)
            .then(res => handleResponse(res, this.props))
            .then(parsed => {
                this.setState({
                    news: this.formatNews(parsed.message.data),
                    newsCount: parsed.message.count,
                    page: 0,
                    maxPageFetched: 0,
                    loading: false,
                    updating: false
                })
            }

            )
            .catch(err => {
                this.setState({ loading: false, updating: false });
                swal500(err);
            });
    };

    showMore = (page) => {
        this.setState(
            { updating: true },
            () => {
                const fetchUrl = this.getFetchUrl(page);

                get(fetchUrl)
                    .then(res => handleResponse(res, this.props))
                    .then(parsed =>
                        this.setState((state) => ({
                            news: [...state.news, ...this.formatNews(parsed.message.data)],
                            maxPageFetched: page,
                            loading: false,
                            updating: false
                        }))
                    )
                    .catch(err => {
                        this.setState({ loading: false, updating: false });
                        swal500(err);
                    });
            }
        )
    };

    handleChangePage = (event, newPage) => {
        this.setState(
            { page: newPage },
            () => {
                window.scrollTo(0, 0);
                if (newPage > this.state.maxPageFetched) {
                    this.showMore(newPage);
                }
            }
        );
    };

    validDates = () => {
        return this.state.startDate <= this.state.endDate;
    }

    handleFilter = () => {
        if (!this.state.dateFilter || this.validDates())
            this.getNews();
    }

    handleTextFieldChange = ({ target }) => {
        const { name, value } = target;
        this.setState({ [name]: value });
    }

    handleDateChange = ({ target }) => {
        const { name, value } = target;
        this.setState({ [name]: moment(value) });
    }

    keyPress = (e) => {
        if (e.keyCode === 13)
            this.handleFilter()
    }

    onCloseToast = (toastKey) => () => this.setState({ [toastKey]: '' });

    handleOpenForm = id => id ? this.props.history.push('/home/news/' + id) : this.props.history.push('/home/news/new');

    handleCheckBox = (event) => {
        const { name, checked } = event.target;
        this.setState({ [name]: checked });
    };


    handleDelete = newsId => {
        const news = this.state.news.find(news => news._id === newsId);
        const { palette } = this.props.theme;
        Swal.fire({
            title: "Eliminar novedad",
            text: `¿Estás seguro de que querés eliminar la novedad ${news.title}?`,
            icon: 'warning',
            showCancelButton: true,
            cancelButtonColor: palette.primary.main,
            confirmButtonColor: palette.state.failure,
            cancelButtonText: 'No, conservar novedad',
            confirmButtonText: 'Si, estoy seguro',
        }).then(result => {
            if (result.value) {
                this.setState(
                    { updating: true },
                    () => {
                        del(`news/${news._id}`)
                            .then(res => handleResponse(res, this.props))
                            .then(result =>
                                this.setState(
                                    (prevState) => ({
                                        news: prevState.news.filter(news => news._id !== newsId),
                                        newsCount: prevState.newsCount - 1,
                                        updating: false,
                                        successMessage: MESSAGES.deleted
                                    })
                                ))
                            .then(res => this.getNews())
                            .catch(err => {
                                swal500(err);
                                this.setState({ updating: false });
                            });
                    }
                );
            }
        })
    };

    render() {
        const { classes } = this.props;
        return (
            <div>
                <Card elevation={3} className={classes.header}>
                    <Grid
                        container
                        direction="row"
                        alignItems="center"
                        justify="space-between"
                    >
                        <h2>Novedades</h2>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={this.state.importantFilter}
                                    onChange={this.handleCheckBox}
                                    name='importantFilter'
                                />
                            }
                            label='Solo importantes'
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={this.state.onlyMineFilter}
                                    onChange={this.handleCheckBox}
                                    name='onlyMineFilter'
                                />
                            }
                            label='Solo mías'
                        />
                        <div className={classes.dateInput}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={this.state.dateFilter}
                                        onChange={this.handleCheckBox}
                                        name='dateFilter'
                                    />
                                }
                                label='Filtrar por fecha'
                            />
                            <TextField
                                name="startDate"
                                label="Desde"
                                type="date"
                                className={classes.dateField}
                                margin="dense"
                                disabled={!this.state.dateFilter}
                                error={this.state.dateFilter && !this.validDates()}
                                helperText={this.state.dateFilter && !this.validDates() && "La fecha de inicio tiene que ser"}
                                defaultValue={this.state.startDate.format('YYYY-MM-DD')}
                                onChange={this.handleDateChange}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                            <TextField
                                name="endDate"
                                label="Hasta"
                                type="date"
                                className={classes.dateField}
                                margin="dense"
                                disabled={!this.state.dateFilter}
                                error={this.state.dateFilter && !this.validDates()}
                                helperText={this.state.dateFilter && !this.validDates() && "anterior a la fecha de fin"}
                                FormHelperTextProps={{ margin: 'dense' }}
                                defaultValue={this.state.endDate.format('YYYY-MM-DD')}
                                onChange={this.handleDateChange}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </div>
                        <div className={classes.searchInput} >
                            <TextField
                                name='titleFilter'
                                label="Buscar por título"
                                value={this.state.titleFilter}
                                onKeyDown={this.keyPress}
                                onChange={this.handleTextFieldChange}
                            />
                            <div className={classes.searchButton}>
                                <IconButton onClick={this.handleFilter} color="secondary">
                                    <Search />
                                </IconButton>
                            </div>
                        </div>
                    </Grid>
                </Card>
                <TableComponent
                    data={this.state}
                    path="/home/news/"
                    newMessage="Nueva novedad"
                    dataKey="news"
                    columns={COLUMNS}
                    handleOpenForm={this.handleOpenForm}
                    handleDelete={this.handleDelete}
                    handleChangePage={this.handleChangePage}
                    onCloseToast={this.onCloseToast}
                    successMessage={this.state.successMessage}
                />
            </div>
        )
    }
}

export default withTheme(withStyles(styles)(NewsTableContainer));
