import React from 'react';
import {
    Grid,
    TextField,
    Typography,
    withStyles
} from "@material-ui/core";
import styles from "./styles";
import { Autocomplete } from '@material-ui/lab';

const OnboardingComponent = ({
    classes,
    clubs,
    handleSubmit,
    loading }) => {
    return (
        <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justify="center"
            className={classes.gridOnboarding}
        >
            <form className={classes.formOnboarding}>

                <img
                    src={'/assets/clubify_logo.png'}
                    width={105}
                    height={97.5}
                    alt="Logo"
                    style={{ margin: '0 auto' }}
                />
                <Typography
                    component="h1"
                    variant="h5"
                    className={classes.title}
                >
                    Elegí tu Club
                </Typography>
                <Autocomplete
                    id="club"
                    options={clubs}
                    onChange={(e, club) => handleSubmit(club)}
                    renderOption={club => (
                        <div className={classes.clubOption}>
                            <img
                                src={`/assets/${club.crestImagePath}`}
                                width={70}
                                height={65}
                                alt="Escudo del Club"
                            />
                            <Typography
                                variant="body1"
                                style={{
                                    marginLeft: 10
                                }}
                                noWrap
                            >
                                {club.fullName}
                            </Typography>
                        </div>
                    )}
                    getOptionLabel={option => option.fullName}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label="Club"
                        />
                    )}
                    noOptionsText='No hay Clubes'
                    loading={loading}
                    loadingText='Buscando Clubes...'
                />
            </form>
        </Grid>
    )
};

// export default OnboardingComponent;
export default withStyles(styles)(OnboardingComponent);