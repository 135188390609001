
export default (values) => {
    let errors = {};

    if (!values.name) {
        errors.name = "Complete el nombre";
    }

    if (!values.discipline) {
        errors.discipline = "Seleccione una disciplina";
    }

    if(Array.isArray(values.players)) {

        if(values.players.length === 0) 
            errors.players = "Debe agregar al menos un jugador."
    }

    if(values.newPlayer)
    {
        if(Array.isArray(values.players))
        {
            if(values.players.some(p => p._id === values.newPlayer._id))
                errors.newPlayer = "El jugador ya está en el seleccionado"
        }
    }
    
    return errors;
}