import React from "react";
import { IconButton, TextField, withStyles } from "@material-ui/core";
import { Create as CreateIcon, Delete as DeleteIcon } from '@material-ui/icons';
import styles from "./styles";
import { FIELDS } from "./FAQsFormContainer";
import { FormLayout } from "../../common";

const FAQsFormComponent = (props) => {
    const {
        classes,
        errors,
        handleChange,
        handleBlur,
        handleClose,
        handleResult,
        handleDelete,
        submitting,
        loading,
        values,
        editMode,
        setEditMode,
        isNewFAQ,
    } = props;

    const renderActions = () => (
        <div className={classes.topActionsContainer}>
            {!editMode && (
                <IconButton onClick={() => setEditMode(true)}>
                    <CreateIcon />
                </IconButton>
            )}
            {!isNewFAQ &&
                <IconButton onClick={handleDelete}>
                    <DeleteIcon />
                </IconButton>
            }
        </div>
    );

    return (
        <FormLayout
            handleClose={handleClose}
            renderActions={renderActions}
            loading={loading}
            editMode={editMode}
            submitting={submitting}
            handleSubmit={handleResult}
            isNew={isNewFAQ}
            dataKey="Pregunta Frecuente"
        >
            <TextField
                name='question'
                error={errors.question !== undefined}
                helperText={errors.question}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.question}
                label={FIELDS['question']}
                className={classes.question}
                InputProps={{
                    readOnly: !editMode,
                    disableUnderline: !editMode
                }}
            />
            {editMode && <div className={classes.divider} />}
            <TextField
                name='answer'
                error={errors.body !== undefined}
                helperText={errors.answer}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.answer}
                label={FIELDS['answer']}
                className={classes.textField}
                InputProps={{
                    readOnly: !editMode,
                    disableUnderline: !editMode
                }}
                multiline={true}
            />
        </FormLayout>
    );
};

export default withStyles(styles)(FAQsFormComponent);