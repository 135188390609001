import React from "react";
import {
    Button,
    CircularProgress,
    IconButton,
    withStyles,
    TextField,
    Card,
    Typography,
    Tooltip
} from "@material-ui/core";
import { Create as CreateIcon, Delete as DeleteIcon, Assignment } from '@material-ui/icons';
import styles from "./styles";
import { FIELDS } from "./ActivitiesFormContainer";
import { ApiAutocomplete, FormLayout } from "../../common";
import WeekScheduler from "../WeekScheduler/WeekScheduler";
import { NoEditComponent } from "../../../utils/utils";

const ActivitiesFormComponent = (props) => {
    const {
        classes,
        errors,
        handleChange,
        handleBlur,
        handleClose,
        handleResult,
        handleDelete,
        handleImageChange,
        submitting,
        loading,
        values,
        editMode,
        setEditMode,
        isNewActivity,
        localImagePath,
        isEditable,
    } = props;

    const renderActions = () => (
        <div className={classes.topActionsContainer}>
            <div className={classes.actionBar}>
                {!isNewActivity &&
                    (<Tooltip title="Administrar parcipantes">
                        <IconButton aria-label="participantes" onClick={() => props.history.push(
                            {
                                pathname: `/home/activities/${values._id}/participants`,
                                state: {
                                    isEditable
                                }
                            })}>
                            <Assignment />
                        </IconButton>
                    </Tooltip>)
                }
                {isEditable ?
                    <div>
                        {!editMode && (
                            <IconButton onClick={() => setEditMode(true)}>
                                <CreateIcon />
                            </IconButton>
                        )}
                        {!isNewActivity &&
                            <IconButton onClick={handleDelete}>
                                <DeleteIcon />
                            </IconButton>
                        }
                    </div>
                    : <Tooltip title="Esta actividad será eliminada en el próximo período de facturación"><p>No es posible editar</p></Tooltip>}
            </div>
        </div>
    );
    const ImageCard = () => (
        <div>
            <Typography variant="caption" gutterBottom className={classes.imageLabel}>
                Imagen
            </Typography>
            <Card elevation={3} className={classes.imageContainer}>
                <img alt="Imagen de la actividad" src={localImagePath ? localImagePath : values.detailImage} className={classes.image} />
                {errors.detailImage ?
                    <Typography variant="h6" gutterBottom className={classes.imageLabelError}>
                        {errors.detailImage}
                    </Typography>
                    : null}
            </Card>
            {editMode &&
                <div className={classes.imageCardActions}>
                    <input
                        accept="image/*"
                        type='file'
                        onChange={handleImageChange}
                        id="upload-file"
                        className={classes.inputFile}
                    />
                    <label className={classes.imageCardLabel} htmlFor="upload-file">
                        {submitting &&
                            <CircularProgress color="primary" size={22} style={{ marginRight: 10 }} />
                        }
                        <Button
                            component='span'
                            color='primary'
                            variant='contained'
                            disabled={loading || submitting}
                            className={classes.imageButton}
                        >
                            Actualizar imagen
                        </Button>
                    </label>
                </div>
            }
        </div>
    );

    return (
        <FormLayout
            handleClose={handleClose}
            renderActions={renderActions}
            loading={loading}
            editMode={editMode}
            submitting={submitting}
            handleSubmit={handleResult}
            isNew={isNewActivity}
            dataKey="Actividad"
        >
            <TextField
                name='name'
                error={errors.name !== undefined}
                helperText={errors.name}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.name}
                label={FIELDS['name']}
                className={classes.textField}
                InputProps={{
                    readOnly: !editMode,
                    disableUnderline: !editMode
                }}
            />
            {editMode && <div className={classes.divider} />}
            <TextField
                name='description'
                error={errors.description !== undefined}
                helperText={errors.description}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.description}
                label={FIELDS['description']}
                className={classes.textField}
                InputProps={{
                    readOnly: !editMode || submitting,
                    disableUnderline: !editMode
                }}
            />

            {editMode && <div className={classes.divider} />}
            <TextField
                name='price'
                error={errors.price !== undefined}
                helperText={errors.price}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.price}
                label={FIELDS['price']}
                type="number"
                className={classes.textField}
                InputProps={{
                    readOnly: !editMode || submitting,
                    disableUnderline: !editMode
                }}
            />
            {editMode && <div className={classes.divider} />}
            <TextField
                name='lifelongPrice'
                error={errors.lifelongPrice !== undefined}
                helperText={errors.lifelongPrice}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.lifelongPrice}
                label={FIELDS['lifelongPrice']}
                className={classes.textField}
                InputProps={{
                    readOnly: !editMode || submitting,
                    disableUnderline: !editMode
                }}
            />
            {editMode && <div className={classes.divider} />}
            <TextField
                name='enrollmentSlots'
                error={errors.enrollmentSlots !== undefined}
                helperText={errors.enrollmentSlots}
                onChange={handleChange}
                type="number"
                onBlur={handleBlur}
                value={values.enrollmentSlots}
                label={FIELDS['enrollmentSlots']}
                disabled={!editMode || submitting}
                className={classes.textField}
                InputProps={{
                    readOnly: !editMode || submitting,
                    disableUnderline: !editMode
                }}
            />
            {editMode && <div className={classes.divider} />}
            <TextField
                name='ageFrom'
                error={errors.ageFrom !== undefined}
                helperText={errors.ageFrom}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.ageFrom}
                type="number"
                label={FIELDS['ageFrom']}
                disabled={!editMode || submitting}
                className={classes.textField}
                InputProps={{
                    readOnly: !editMode || submitting || !isNewActivity,
                    disableUnderline: !editMode || !isNewActivity
                }}
            />
            <NoEditComponent isNew={isNewActivity} isInEdit={editMode}/>
            {editMode && <div className={classes.divider} />}
            <TextField
                name='ageTo'
                error={errors.ageTo !== undefined}
                helperText={errors.ageTo}
                onChange={handleChange}
                type="number"
                onBlur={handleBlur}
                value={values.ageTo}
                label={FIELDS['ageTo']}
                disabled={!editMode || submitting}
                className={classes.textField}
                InputProps={{
                    readOnly: !editMode || submitting || !isNewActivity,
                    disableUnderline: !editMode || !isNewActivity
                }}
            />
            <NoEditComponent isNew={isNewActivity} isInEdit={editMode}/>
            {editMode && <div className={classes.divider} />}
            <ApiAutocomplete
                error={errors.discipline}
                handleChange={handleChange}
                label='name'
                name='discipline'
                path='disciplines?name=like:'
                title={FIELDS["discipline"]}
                value={values.discipline}
                noOptionsText="No hay disciplinas con ese nombre"
                disabled={!editMode || submitting}
                dataField="disciplines"
            />
            {editMode && <div className={classes.divider} />}
            <ApiAutocomplete
                error={errors.requiredMembershipType}
                handleChange={handleChange}
                label='name'
                name='requiredMembershipType'
                path='membershiptypes?name=like:'
                title={FIELDS["requiredMembershipType"]}
                value={values.requiredMembershipType}
                noOptionsText="No hay Tipos de Abono con ese nombre"
                disabled={!editMode || submitting}
                dataField="membershipTypes"
            />
            {editMode && <div className={classes.divider} />}
            <ApiAutocomplete
                error={errors.teacher}
                handleChange={handleChange}
                label={(user) => `${user.firstName} ${user.lastName}`}
                name='teacher'
                path='users?email=like:'
                title={FIELDS["teacher"]}
                value={values.teacher}
                noOptionsText="No hay docentes con ese correo electrónico"
                filterLabel={(value) => value.email}
                disabled={!editMode || submitting}
                dataField="data"
            />

            {editMode && <div className={classes.divider} />}
            <WeekScheduler {...props} FIELDS={FIELDS} classes={null} />

            {editMode && <div className={classes.divider} />}
            <ImageCard />


        </FormLayout>
    );
};

export default withStyles(styles)(ActivitiesFormComponent);