import React, { useState, useEffect } from 'react';
import { handleResponse, post, patch, swal500, del, get } from '../../../utils/network';
import { useFormValidation } from "../../common";
import validateActivity from './validator';
import * as Swal from "sweetalert2";
import ActivitiesFormComponent from './ActivitiesFormComponent';
import { isMongoId } from '../../../utils/utils';
import config from '../../../utils/config';

export const FIELDS = {
    name: "Nombre de la Actividad",
    description: "Descripción",
    discipline: "Disciplina asociada",
    price: "Precio general",
    ageFrom: "Edad mínima (opcional)",
    ageTo: "Edad máxima (opcional)",
    lifelongPrice: "Precio vitalicio",
    schedules: "Horarios",
    teacher: "Docente (opcional)",
    requiredMembershipType: "Tipo de Abono requerido (opcional)",
    enrollmentSlots: "Máximo de inscriptos (opcional)"
};

const INITIAL_STATE = {
    _id: '',
    name: '',
    description: '',
    price: '',
    ageFrom: '',
    ageTo: '',
    lifelongPrice: '',
    detailImage: '',
    hasPendingCancellation: false,
    erasedOnNextPeriod: false,
    teacher: null,
    schedules: [],
    discipline: null,
    pendingCancellations: [],
    requiredMembershipType: null,
    enrollmentSlots: ''
};

const ActivitiesFormContainer = (props) => {
    const [data, setData] = useState({});
    const [editMode, setEditMode] = useState(props.location.editMode || false);
    const [loading, setLoading] = useState(true);
    const [submitting, setSubmitting] = useState(false);
    const [localImagePath, setLocalImagePath] = useState(null);

    const {
        handleSubmit,
        handleChange,
        handleChanges,
        runValidations,
        handleBlur,
        values,
        errors,
        setErrors,
        mergeErrors,
        resetValues
    } = useFormValidation(INITIAL_STATE, values => validateActivity(values));
    const isNewActivity = Object.entries(data).length === 0;

    const getChangedValues = values => {
        const changedValues = [];
        Object.keys(values).forEach(key => {
            data[key] !== values[key] && changedValues.push(key)
        });

        return changedValues.filter(v => v !== 'created');
    };

    const handle400 = err => {
        err.text().then(error => {
            let errorObject = JSON.parse(error);
            let errors = {};
            errorObject.fields.forEach(field => {
                const regex = new RegExp(field.name);

                const internalName = (field.name.includes("[") ? field.name.split("[")[0] : field.name)

                errors[internalName] = field.message.replace(regex, FIELDS[field.name]);
            });
            setErrors(errors);
        });
        return Promise.reject(400);
    };

    const handleClose = () => props.history.push('/home/activities');

    useEffect(() => {
        const loadActivity = (id) => {
            return get('activities/' + id + "?app=bof")
                .then(res => handleResponse(res, props))
                .then(parsed => parsed.message ? Promise.resolve(parsed.message) : Promise.reject("Error en la respuesta"))
                .then(activity => ({ ...activity, detailImage: `${config.backEndUrl}images/activity/${activity._id}` }))
                .catch(err => {
                    setLoading(false);
                    swal500(err);
                });
        };

        if (props.match.params.id === 'new') {
            setEditMode(true);
            setLoading(false);
        } else if (isMongoId(props.match.params.id)) {

            const activityID = props.match.params.id;

            loadActivity(activityID)
                .then(activity => {
                    const stateKeys = Object.keys(INITIAL_STATE);
                    const validKeys = {}

                    //copy only valid fields
                    stateKeys.forEach(k => {
                        if (activity.hasOwnProperty(k))
                            validKeys[k] = activity[k]
                    });

                    setData(validKeys)
                    resetValues(validKeys);
                    setLoading(false);
                })

        } else
            handleClose();
    }, [props, resetValues]);


    const handleDelete = () => {
        Swal.fire({
            title: "Eliminar actividad",
            text: `¿Estás seguro de que querés eliminar la actividad '${values.name}'?`,
            icon: 'warning',
            showCancelButton: true,
            cancelButtonColor: '#4eaaf3', //TODO: theme.palette.primary.main
            confirmButtonColor: "#FF0000", //TODO: theme.palette.state.failure
            cancelButtonText: 'No, conservar actividad',
            confirmButtonText: 'Si, estoy seguro',
            showLoaderOnConfirm: true,
            preConfirm: () => {
                Swal.update({ showCancelButton: false })
                return del(`activities/${props.match.params.id}`)
                    .then(res => handleResponse(res, props))
                    .then(result =>
                        props.history.push({
                            pathname: '/home/activities',
                            state: { msg: 'deleted' },
                        })
                    )
                    .catch(err => {
                        swal500(err);
                    });
            }
        });
    };

    const handleResult = (e) => {

        const formValid = handleSubmit(e);
        if (formValid) {
            setSubmitting(true);
            if (isNewActivity) {
                const formData = new FormData();
                formData.append('name', values.name);
                formData.append('description', values.description);
                formData.append('discipline', values.discipline._id);
                formData.append('price', values.price);
                formData.append('lifelongPrice', values.lifelongPrice);
                formData.append('schedules', JSON.stringify(values.schedules));

                if (values.ageFrom)
                    formData.append('ageFrom', values.ageFrom);

                if (values.ageTo)
                    formData.append('ageTo', values.ageTo);

                if (typeof values.detailImage == "object")
                    formData.append('detailImage', values.detailImage);

                if (values.requiredMembershipType)
                    formData.append('requiredMembershipType', values.requiredMembershipType._id);

                if (values.teacher)
                    formData.append('teacher', values.teacher._id);

                if (values.enrollmentSlots)
                    formData.append('enrollmentSlots', values.enrollmentSlots);

                post('activities', formData, true, { 'Accept': 'Application/json' }, true)
                    .then(res => handleResponse(res, props, [{ status: 400, method: handle400 }]))
                    .then(res => {
                        setSubmitting(false);
                        props.history.push({
                            pathname: '/home/activities',
                            state: { msg: 'created' },
                        });
                    })
                    .catch(err => {
                        setSubmitting(false);
                        if (err !== 400) {
                            swal500(err);
                        }
                    });
            } else {
                const changedValues = getChangedValues(values);
                if (changedValues.length === 0) {
                    props.history.push('/home/activities');
                    return;
                }

                const formData = new FormData();

                //remove uneditable fields if they were edited through the console
                changedValues.filter(e => e != "ageTo" && e != "ageFrom").forEach(value => {
                    if(value === "schedules")
                        formData.append([value], JSON.stringify(values[value]));
                    else if (value === "enrollmentSlots")
                        formData.append([value], values[value] || "");
                    else if (value === "requiredMembershipType" || value === "teacher" || value === "discipline")
                        formData.append([value], values[value]?._id || "");
                    else
                        formData.append([value], values[value]);
                });

                patch(`activities/${data._id}`, formData, true, { 'Accept': 'Application/json' }, true)
                    .then(res => handleResponse(res, props, [{ status: 400, method: handle400 }]))
                    .then(res => {
                        setSubmitting(false);
                        props.history.push({
                            pathname: '/home/activities',
                            state: { msg: 'edited' },
                        });
                    })
                    .catch(err => {
                        setSubmitting(false);
                        if (err !== 400) {
                            swal500(err);
                        }
                    });
            }
        }
    };

    const handleImageChange = ({ target }) => {

        handleChange({
            target: {
                name: "detailImage",
                value: target.files[0]
            }
        })
    }

    const detailImage = values.detailImage;

    useEffect(() => {
        if (typeof detailImage == "object")
            setLocalImagePath(URL.createObjectURL(detailImage))

    }, [detailImage]);

    useEffect(() => {

        handleBlur({
            target: {
                name: "detailImage"
            }
        })

    }, [localImagePath]);

    const isEditable = () => !values.erasedOnNextPeriod

    return (
        <ActivitiesFormComponent
            errors={errors}
            handleChange={handleChange}
            handleBlur={handleBlur}
            handleClose={handleClose}
            handleImageChange={handleImageChange}
            handleResult={handleResult}
            handleDelete={handleDelete}
            loading={loading}
            submitting={submitting}
            data={data}
            values={values}
            editMode={editMode && isEditable()}
            setEditMode={setEditMode}
            isNewActivity={isNewActivity}
            localImagePath={localImagePath}
            isEditable={isEditable()}
            handleChanges={handleChanges}
            runValidations={runValidations}
            setErrors={setErrors}
            mergeErrors={mergeErrors}
            location={props.location}
            history={props.history}
        />
    );
};

export default ActivitiesFormContainer;