export const ADDRESS = /.+(,.+){1,2}/;
export const NAME_REGEX = /^([a-zA-ZÀ-úÜü]+\s?)+$/;

export default (values) => {
    let errors = {};

    if (!values.name) {
        errors.name = "Complete el nombre";
    } else if (!NAME_REGEX.test(values.name)) {
        errors.name = "El nombre debe ser un nombre válido"
    }

    if (!values.address) {
        errors.address = "Complete la dirección";
    } else if (!ADDRESS.test(values.address)) {
        errors.address = "La dirección tener el formato calle, municipio, localidad. Ejemplo: Balcarce 50, CABA, CABA"
    }
    
    return errors;
}