import React, { Component } from 'react';
import { withTheme } from '@material-ui/core';
import { handleResponse, get, swal500 } from '../../../utils/network';
import { TableComponent } from "../../common";
import COLUMNS from "./columns";

const MESSAGES = {
    created: 'La disciplina ha sido creada correctamente',
    edited: 'La disciplina ha sido editada correctamente',
    deleted: 'La disciplina ha sido eliminada correctamente'
};

class DisciplinesTableContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            page: 0,
            maxPageFetched: 0,
            editing: false,
            disciplines: [],
            disciplinesCount: 0,
            loading: true,
            updating: false,
            successMessage: ''
        }
    }

    componentDidMount() {
        this.getDisciplines();
        const msgKey = this.props.location.state?.msg;
        if (msgKey) {
            const msg = MESSAGES[msgKey];
            msg && this.setState({ successMessage: msg });
        }
    };

    formatDisciplines = (disciplines) => {
        return disciplines.map(discipline => {
            const disciplineCopy = { ...discipline };
            disciplineCopy.isCompetitive = disciplineCopy.competitive ? "Si" : "No";
            return disciplineCopy;
        });
    }

    getDisciplines = () => {
        get('disciplines?page=0')
            .then(res => handleResponse(res, this.props))
            .then(parsed => {
                this.setState({
                    disciplines: this.formatDisciplines(parsed.message.disciplines),
                    disciplinesCount: parsed.message.count,
                    page: 0,
                    maxPageFetched: 0,
                    loading: false,
                    updating: false
                })
            }
            )
            .catch(err => {
                this.setState({ loading: false, updating: false });
                swal500(err);
            });
    };

    showMore = (page) => {
        this.setState(
            { updating: true },
            () => {
                get('disciplines?page=' + page)
                    .then(res => handleResponse(res, this.props))
                    .then(parsed =>
                        this.setState(prevState => ({
                            disciplines: prevState.disciplines.concat(this.formatDisciplines(parsed.message.disciplines)),
                            maxPageFetched: page,
                            loading: false,
                            updating: false
                        }))
                    )
                    .catch(err => {
                        this.setState({ loading: false, updating: false });
                        swal500(err);
                    });
            }
        )
    };

    handleChangePage = (event, newPage) => {
        this.setState(
            { page: newPage },
            () => {
                window.scrollTo(0, 0);
                if (newPage > this.state.maxPageFetched) {
                    this.showMore(newPage);
                }
            }
        );
    };

    onCloseToast = (toastKey) => () => this.setState({ [toastKey]: '' });

    handleOpenForm = (id, stateToSend) => id ? this.props.history.push({
        pathname: '/home/disciplines/' + id,
        state: stateToSend,
    })
        :
        this.props.history.push('/home/disciplines/new');

    navStateSelector = ({ _id, name, competitive }) =>
        ({ _id, name, competitive })

    render() {
        return (
            <TableComponent
                data={this.state}
                path="/home/disciplines/"
                newMessage="Nueva Disciplina"
                columns={COLUMNS}
                dataKey="disciplines"
                handleOpenForm={this.handleOpenForm}
                handleChangePage={this.handleChangePage}
                onCloseToast={this.onCloseToast}
                successMessage={this.state.successMessage}
                setDeletable={() => false}
                navStateMapper={this.navStateSelector}
            />
        )
    }
}

export default withTheme(DisciplinesTableContainer);
