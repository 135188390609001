import React, { useState } from 'react';
import {
    Dialog,
    DialogContent,
    DialogTitle,
    withStyles,
    useTheme,
    useMediaQuery,
    Typography,
    DialogActions,
    Button,
    CircularProgress
} from "@material-ui/core";
import { CustomInput } from "../../components/common";
import { Email } from '@material-ui/icons';
import { handleResponse, post, swal500 } from "../../utils/network";
import { useSelector } from 'react-redux';

const EMAIL_REGEX = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const styles = theme => ({
    dialog: {
        padding: 0
    },
});

const PasswordResetDialog = (props) => {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('xs'));
    const {
        open,
        classes,
        onClose,
        onSubmit,
    } = props;
    const [email, setEmail] = useState("");
    const [loading, setLoading] = useState(false);
    const [erroremail, setErroremail] = useState("");
    const { shortName: clubShortName } = useSelector((state) => state);

    const handleChange = (e) => {
        setErroremail("");
        setEmail(e.target.value);
    };

    const handleSubmit = () => {
        if (email && EMAIL_REGEX.test(email)) {
            setLoading(true);
            post("auth/passwordreset", { email, clubShortName })
                .then(res => handleResponse(res, props, [{ status: 404, method: () => Promise.reject(404) }]))
                .then(res => {
                    setLoading(false);
                    resetForm();
                    onClose();
                    onSubmit();
                })
                .catch(err => {
                    setLoading(false);
                    if (err === 404) {
                        setErroremail("El correo electrónico ingresado no se encuentra registrado en el sistema")
                    } else {
                        onClose();
                        swal500(err);
                    }
                });
        } else {
            setErroremail("Ingrese un correo electrónico válido")
        }
    };

    const resetForm = () => {
        setEmail("");
        setErroremail("");
    };

    const closeDialog = () => {
        resetForm();
        onClose();
    };

    return (
        <Dialog
            fullScreen={fullScreen}
            className={classes.dialog}
            onClose={closeDialog}
            open={open}
            maxWidth="xs"
            fullWidth
        >
            <DialogTitle style={{ padding: "20px 24px 7px" }}>
                Recuperar contraseña
            </DialogTitle>
            <DialogContent>
                <Typography>
                    Ingrese su correo electronico:
                </Typography>
                <CustomInput
                    error={erroremail}
                    name="email"
                    label="Correo electrónico"
                    type="email"
                    handleChange={handleChange}
                    value={email}
                    icon={<Email style={{ marginRight: 5, color: "grey" }} />}
                />
            </DialogContent>
            <DialogActions>
                {loading && <CircularProgress size={22} color="primary" />}
                <Button
                    onClick={closeDialog}
                    disabled={loading}
                    style={{ color: 'gray' }}
                >
                    Cancelar
                </Button>
                <Button
                    color="primary"
                    type="submit"
                    onClick={handleSubmit}
                    disabled={loading}
                >
                    Confirmar
                </Button>
            </DialogActions>
        </Dialog>
    )
};

export default withStyles(styles)(PasswordResetDialog);

