export default theme => ({
    root: {
        width: '100%',
        marginBottom: 20,
        margin: '0 auto',
        [theme.breakpoints.down('sm')]: {
            marginBottom: 10,
            width: '100%'
        }
    },
    tableHead: {
        fontWeight: 550,
        color: 'grey',
        backgroundColor: "white"
    },
    iconsContainer:{
        display:'flex',
        justifyContent: 'flex-end',
    },
    icon: {
        color: "rgba(0, 0, 0, 0.4)"
    },
    tableCell: {
        border: 'none'
    },
    pagination: {
        color: 'grey'
    },
    buttonContainerUpdating: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        backgroundColor: 'white',
        [theme.breakpoints.down('sm')]:{
            paddingRight: 25
        }
    },
    fabIcon: {
        boxShadow: '0px 1px 1px -1px rgba(0,0,0,0.2), 0px 2px 12px 0px rgba(0,0,0,0.14), 0px 0px 1px 0px rgba(0,0,0,0.12)',
        padding: "0 12px !important",
        [theme.breakpoints.down(1021)]:{
            width: 35,
            height: 35
        }
    },
    loadingContainer: {
        display:'flex',
        flexDirection: 'column',
        justifyContent:'center',
        alignItems: 'center',
        height: '60vh'
    }
});
