export default () => ({
  topActionsContainer: {
    display: 'flex',
    justifyContent: 'space-around',
    alignSelf: 'center',
    marginRight: 15
  },
  textField: {
    marginTop: 13,
    marginLeft: "15px"
  },
  comboBox: {
    marginTop: 13
  },
  divider: {
    height: 20
  },
  colorChooser: {
    display:"inline-flex",
    alignItems: "center"
  },
  FAB: {
    marginTop: "25px",
    marginLeft: "15px"
  },
  colorPicker: {
    marginLeft: "15px",
    width: "100%",
    maxWidth: "400px",
    marginTop: "17px"
  }
});
