import React from "react";
import { IconButton, TextField, withStyles } from "@material-ui/core";
import { Delete as DeleteIcon } from '@material-ui/icons';
import styles from "./styles";
import { FIELDS } from "./ReservationsFormContainer";
import { FormLayout } from "../../common";
import { getShiftPrice } from '../utils';
import moment from 'moment';
import 'moment/locale/es';

const ReservationsFormComponent = (props) => {
    const {
        classes,
        errors,
        handleChange,
        handleBlur,
        handleClose,
        handleDateChange,
        handleResult,
        handleDelete,
        submitting,
        loading,
        values,
        facility,
        isNewReservation,
    } = props;

    const renderActions = () => (
        <div className={classes.topActionsContainer}>
            {!isNewReservation &&
                <IconButton onClick={handleDelete}>
                    <DeleteIcon />
                </IconButton>
            }
        </div>
    );

    const onStartchange = (event) => {
        handleDateChange(event);
        const { value } = event.target;
        const endDate = moment(value).add(facility.shiftDuration, 'minutes').format('YYYY-MM-DDTHH:mm')
        handleDateChange({
            target: {
                name: 'end',
                value: endDate
            }
        });
    }

    return (
        <FormLayout
            handleClose={handleClose}
            renderActions={renderActions}
            loading={loading}
            editMode={isNewReservation}
            isNew={isNewReservation}
            submitting={submitting}
            handleSubmit={handleResult}
            dataKey="Reserva"
        >
            <TextField
                name='facility'
                value={facility.name}
                label={FIELDS['facility']}
                className={classes.textField}
                InputProps={{
                    readOnly: true,
                    disableUnderline: true
                }}
            />
            <>
                <TextField
                    name='memberNumber'
                    error={errors.memberNumber !== undefined}
                    helperText={errors.memberNumber}
                    onChange={handleChange}
                    type="number"
                    onBlur={handleBlur}
                    value={values.memberNumber || values.reservor?.memberNumber}
                    label={FIELDS['memberNumber']}
                    className={classes.textField}
                    InputProps={{
                        readOnly: !isNewReservation,
                        disableUnderline: !isNewReservation
                    }}
                />
                <TextField
                    name='firstName'
                    value={values.reservor?.firstName || ''}
                    label={FIELDS['firstName']}
                    className={classes.textField}
                    InputProps={{
                        readOnly: true,
                        disableUnderline: true
                    }}
                />
                <TextField
                    name='lastName'
                    value={values.reservor?.lastName || ''}
                    label={FIELDS['lastName']}
                    className={classes.textField}
                    InputProps={{
                        readOnly: true,
                        disableUnderline: true
                    }}
                />
                <TextField
                    name='email'
                    value={values.reservor?.email || ''}
                    label={FIELDS['email']}
                    className={classes.textField}
                    InputProps={{
                        readOnly: true,
                        disableUnderline: true
                    }}
                />
            </>
            <TextField
                name="start"
                label={FIELDS['start']}
                className={classes.textField}
                type="datetime-local"
                error={errors.start !== undefined}
                helperText={errors.start}
                onChange={onStartchange}
                value={moment(values.start).format('YYYY-MM-DDTHH:mm')}
                onBlur={handleBlur}
                InputLabelProps={{
                    shrink: true
                }}
                InputProps={{
                    readOnly: !isNewReservation,
                    disableUnderline: !isNewReservation
                }}
            />
            <TextField
                name="end"
                label={FIELDS['end']}
                className={classes.textField}
                type="datetime-local"
                error={errors.end !== undefined}
                helperText={errors.end}
                onBlur={handleBlur}
                value={moment(values.end).format('YYYY-MM-DDTHH:mm')}
                InputLabelProps={{
                    shrink: true
                }}
                InputProps={{
                    readOnly: true,
                    disableUnderline: true
                }}
            />
            <TextField
                name='shiftPrice'
                value={getShiftPrice(values.start, facility)}
                label={FIELDS['shiftPrice']}
                className={classes.textField}
                InputProps={{
                    readOnly: true,
                    disableUnderline: true
                }}
            />
        </FormLayout >
    );
};

export default withStyles(styles)(ReservationsFormComponent);