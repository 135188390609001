import React, { useState, useEffect } from 'react';
import { handleResponse, post, patch, swal500 } from '../../../utils/network';
import { useFormValidation } from "../../common";
import validateDiscipline from './validator';
import DisciplinesFormComponent from "./DisciplinesFormComponent";

export const FIELDS = {
    name: "Nombre",
    competitive: "Competitiva"
};

const INITIAL_STATE = {
    name: '',
    competitive: false
};

const DisciplinesFormContainer = (props) => {
    const [data, setData] = useState({});
    const [editMode, setEditMode] = useState(props.location.editMode || false);
    const [submitting, setSubmitting] = useState(false);
    const isNewDiscipline = Object.entries(data).length === 0;

    const {
        handleSubmit,
        handleChange,
        handleCheckBox,
        handleBlur,
        values,
        errors,
        setErrors,
        resetValues
    } = useFormValidation(INITIAL_STATE, values => validateDiscipline(values));

    const getChangedValues = values => {
        const changedValues = [];
        Object.keys(values).forEach(key => {
            data[key] !== values[key] && changedValues.push(key)
        });
        return changedValues.filter(v => v !== 'created');
    };

    const handle400 = err => {
        err.text().then(error => {
            let errorObject = JSON.parse(error);
            let errors = {};
            errorObject.fields.forEach(field => {
                const regex = new RegExp(field.name);
                errors[field.name] = field.message.replace(regex, FIELDS[field.name]);
            });
            setErrors(errors);
        });
        return Promise.reject(400);
    };

    const handleClose = () => props.history.push('/home/disciplines');

    useEffect(() => {
        const { state } = props.location;
        if (props.match.params.id === 'new') {
            setEditMode(true);
            return;
        } else if (state) {
            setData(state);
            resetValues(state);
            return;
        }
        handleClose();
    }, [props, resetValues]);

    const handleResult = (e) => {
        const formValid = handleSubmit(e);
        if (formValid) {
            setSubmitting(true);
            if (isNewDiscipline) {
                post('disciplines', {
                    name: values.name,
                    competitive: values.competitive
                })
                    .then(res => handleResponse(res, props, [{ status: 400, method: handle400 }]))
                    .then(res => {
                        setSubmitting(false);
                        props.history.push({
                            pathname: '/home/disciplines',
                            state: { msg: 'created' },
                        });
                    })
                    .catch(err => {
                        setSubmitting(false);
                        if (err !== 400) {
                            swal500(err);
                        }
                    });
            } else {
                const body = {};
                const changedValues = getChangedValues(values);
                if (changedValues.length === 0) {
                    props.history.push('/home/disciplines');
                    return;
                }
                changedValues.forEach(value => {
                    Object.assign(body, { [value]: values[value] });
                });
                patch(`disciplines/${data._id}`, body)
                    .then(res => handleResponse(res, props, [{ status: 400, method: handle400 }]))
                    .then(res => {
                        setSubmitting(false);
                        props.history.push({
                            pathname: '/home/disciplines',
                            state: { msg: 'edited' },
                        });
                    })
                    .catch(err => {
                        setSubmitting(false);
                        if (err !== 400) {
                            swal500(err);
                        }
                    });
            }
        }
    };

    return (
        <DisciplinesFormComponent
            errors={errors}
            handleChange={handleChange}
            handleCheckBox={handleCheckBox}
            handleBlur={handleBlur}
            handleClose={handleClose}
            handleResult={handleResult}
            loading={false}
            submitting={submitting}
            values={values}
            editMode={editMode}
            setEditMode={setEditMode}
            isNewDiscipline={isNewDiscipline}
        />
    );
};

export default DisciplinesFormContainer;