import React, { Component } from 'react';
import { withTheme, Tooltip, IconButton } from '@material-ui/core';
import * as Swal from 'sweetalert2';
import { handleResponse, get, del, swal500 } from '../../../utils/network';
import { TableComponent } from "../../common";
import COLUMNS from "./columns";
import { isMongoId } from '../../../utils/utils';
import { Link } from 'react-router-dom';
import { Filter1 } from '@material-ui/icons/index';

const MESSAGES = {
    created: 'El torneo ha sido creado correctamente',
    edited: 'El torneo ha sido editado correctamente',
    deleted: 'El torneo ha sido eliminado correctamente'
};

class TournamentsTableContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            page: 0,
            maxPageFetched: 0,
            editing: false,
            sportTeamId: null,
            tournaments: [],
            tournamentsCount: 0,
            loading: true,
            updating: false,
            successMessage: ''
        }
    }

    handleClose = () => this.props.history.push('/home/sportteams/');

    componentDidMount() {
        const { id } = this.props.match.params;

        if (!(id && isMongoId(id))) {
            this.handleClose();
            return;
        }

        this.setState({ sportTeamId: id });
        this.getTournaments(id);

        const msgKey = this.props.location.state?.msg;
        if (msgKey) {
            const msg = MESSAGES[msgKey];
            msg && this.setState({ successMessage: msg });
        }
    };

    extraActions = (tournament) => {
        return (
            <Link to={{
                pathname: `/home/sportteams/${this.state.sportTeamId}/tournaments/${tournament._id}/matches`,
                state: tournament._id
            }}>
                <Tooltip title="Ver Partidos">
                    <IconButton>
                        <Filter1 />
                    </IconButton>
                </Tooltip>
            </Link>
        )
    };

    formatTournaments = (tournaments) => {
        return tournaments.map(tournament => {
            const tournamentCopy = { ...tournament };
            tournamentCopy.hasFinishedMessage = tournamentCopy.hasFinished ? "Si" : "No";
            tournamentCopy.briefStatistics = tournamentCopy.statistics ? `${tournamentCopy.statistics.position}º - ${tournamentCopy.statistics.points} pts` : "-";
            return tournamentCopy;
        });
    }

    getTournaments = (id) => {
        get(`sportteams/${id}/tournaments?page=0`)
            .then(res => handleResponse(res, this.props))
            .then(parsed => {
                this.setState({
                    tournaments: this.formatTournaments(parsed.message.data),
                    tournamentsCount: parsed.message.count,
                    page: 0,
                    maxPageFetched: 0,
                    loading: false,
                    updating: false
                })
            })
            .catch(err => {
                this.setState({ loading: false, updating: false });
                swal500(err);
                this.handleClose();
            });
    };

    showMore = (page) => {
        this.setState(
            { updating: true },
            () => {
                get(`sportteams/${this.state.sportTeamId}/tournaments?page=${page}`)
                    .then(res => handleResponse(res, this.props))
                    .then(parsed =>
                        this.setState(prevState => ({
                            tournaments: prevState.tournaments.concat(this.formatTournaments(parsed.message.data)),
                            maxPageFetched: page,
                            loading: false,
                            updating: false
                        }))
                    )
                    .catch(err => {
                        this.setState({ loading: false, updating: false });
                        swal500(err);
                    });
            }
        )
    };

    handleChangePage = (event, newPage) => {
        this.setState(
            { page: newPage },
            () => {
                window.scrollTo(0, 0);
                if (newPage > this.state.maxPageFetched) {
                    this.showMore(newPage);
                }
            }
        );
    };

    onCloseToast = (toastKey) => () => this.setState({ [toastKey]: '' });

    handleOpenForm = (id, stateToSend) => id ?
        this.props.history.push({
            pathname: `/home/sportteams/${this.state.sportTeamId}/tournaments/${id}`,
            state: stateToSend
        })
        :
        this.props.history.push(`/home/sportteams/${this.state.sportTeamId}/tournaments/new`);

    handleDelete = tournamentId => {
        const tournament = this.state.tournaments.find(u => u._id === tournamentId);
        const { palette } = this.props.theme;
        Swal.fire({
            title: "Eliminar Torneo",
            text: `¿Estás seguro de que querés eliminar el torneo '${tournament.name}'?`,
            icon: 'warning',
            showCancelButton: true,
            cancelButtonColor: palette.primary.main,
            confirmButtonColor: palette.state.failure,
            cancelButtonText: 'No, conservar torneo',
            confirmButtonText: 'Si, estoy seguro',
        }).then(result => {
            if (result.value) {
                this.setState(
                    { updating: true },
                    () => {
                        del(`tournaments/${tournament._id}`)
                            .then(res => handleResponse(res, this.props))
                            .then(result =>
                                this.setState(
                                    (prevState) => ({
                                        tournaments: prevState.tournaments.filter(r => r._id !== tournamentId),
                                        tournamentsCount: prevState.tournamentsCount - 1,
                                        updating: false,
                                        successMessage: MESSAGES.deleted
                                    })
                                ))
                            .then(res => this.getTournaments(this.state.sportTeamId))
                            .catch(err => {
                                swal500(err);
                                this.setState({ updating: false });
                            });
                    }
                );
            }
        })
    };

    navStateSelector = ({ _id, name, hasFinished, statistics, url }) =>
        ({ _id, name, hasFinished, statistics, url, sportTeam: this.state.sportTeamId })

    render() {
        return (
            <TableComponent
                data={this.state}
                extraActions={this.extraActions}
                path={`/home/sportteams/${this.state.sportTeamId}/tournaments/`}
                newMessage="Nuevo torneo"
                columns={COLUMNS}
                dataKey="tournaments"
                handleOpenForm={this.handleOpenForm}
                handleDelete={this.handleDelete}
                handleChangePage={this.handleChangePage}
                onCloseToast={this.onCloseToast}
                successMessage={this.state.successMessage}
                navStateMapper={this.navStateSelector}
            />
        )
    }
}

export default withTheme(TournamentsTableContainer);
