const Durations = {
    Diaria: 'D',
    "Por fin de semana": 'WE',
    "Mensual corrida": 'MC',
    "Mensual fiscal no autorenovable": 'MF',
    "Mensual fiscal autorenovable": 'MR',
    "Por temporada de verano": 'SS',
    "Anual corrida": 'YC',
    "Anual fiscal no autorenovable": 'YF',
    "Anual fiscal autorenovable": 'YR'
};

export const values = () => Object.values(Durations)

export default Durations