import { stringToDecimalTime } from '../../utils/utils'
import moment from 'moment';
import 'moment/locale/es';

export const getOpenInterval = (start, facility) => {
    const startMoment = moment(start);
    const startTime = stringToDecimalTime(startMoment.format('HH:mm'));
    const endTime = startTime + (facility.shiftDuration / 60);
    const startDayOfWeek = startMoment.day();
    const openingInterval = facility.openingIntervals.find(oIntervals => oIntervals.dayOfWeek === startDayOfWeek);
    if (openingInterval) {
        const interval = openingInterval.intervals.find(interval => stringToDecimalTime(interval.opensAt) <= startTime &&
            endTime <= stringToDecimalTime(interval.closesAt))
        if (interval) {
            return interval;
        }
    }
    else return null;
}

export const getShiftPrice = (start, facility) => {
    const interval = getOpenInterval(start, facility);
    return interval ? interval.shiftPrice : 'No está abierta la instalación en ese horario'
}


