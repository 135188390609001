export default theme => ({
    header: {
        padding: "5px 5px 5px 5px",
        marginBottom: "20px",
        paddingLeft: "20px",
        paddingRight: "20px",
        fontSize: "20px",
        color: "#808080"
    },
    dateInput: {
        height: "100%",
        display: "inline-flex",
        flexDirection: "row"
    },
    searchInput: {
        height: "100%",
        display: "inline-flex",
        flexDirection: "row",
        marginBottom: "6px"
    },
    searchButton: {
        marginTop: "10px"
    },
    bodyContainer: {
        marginTop: "10px"
    },
    textField: {
        marginTop: 13,
        marginLeft: "15px"
    },
    dateField: {
        marginTop: 13,
        marginLeft: 15,
        marginBottom: 26,
        width: 145
    }
});