export default (values) => {
    let errors = {};

    if (!values.name) {
        errors.name = "Complete el nombre";
    }

    if (values.position <= 0) {
        errors.position = "La posición en el Torneo debe debe ser un un entero positivo."
    }

    if (values.numberOfTeams < 2) {
        errors.numberOfTeams = "La cantidad de equipos debe ser un entero mayor o igual a 2."
    }
 
    if (values.points < 0) {
        errors.points = "La cantidad de puntos debe ser un entero no negativo."
    }

    if (values.pointsToFirst < 0) {
        errors.pointsToFirst = "La cantidad de puntos hasta el primero debe ser un entero no negativo."
    }

    if (values.pointsToLast < 0) {
        errors.pointsToLast = "La cantidad de puntos hasta el primero debe ser un entero no negativo."
    }

    return errors;
}