import React from "react";
import { IconButton, Checkbox, FormControlLabel, TextField, withStyles } from "@material-ui/core";
import { Create as CreateIcon, Delete as DeleteIcon } from '@material-ui/icons';
import styles from "./styles";
import { FIELDS } from "./NewsFormContainer";
import { FormLayout } from "../../common";
import { Autocomplete } from '@material-ui/lab';

const NewsFormComponent = (props) => {
    const {
        classes,
        errors,
        handleChange,
        handleCheckBox,
        handleBlur,
        handleClose,
        handleResult,
        handleDelete,
        submitting,
        loading,
        loadingTopics,
        values,
        allTopics,
        editMode,
        setEditMode,
        isNewNews,
    } = props;

    const renderActions = () => (
        <div className={classes.topActionsContainer}>
            {!editMode && (
                <IconButton onClick={() => setEditMode(true)}>
                    <CreateIcon />
                </IconButton>
            )}
            {!isNewNews &&
                <IconButton onClick={handleDelete}>
                    <DeleteIcon />
                </IconButton>
            }
        </div>
    );

    return (
        <FormLayout
            handleClose={handleClose}
            renderActions={renderActions}
            loading={loading}
            editMode={editMode}
            submitting={submitting}
            handleSubmit={handleResult}
            isNew={isNewNews}
            dataKey="Novedad"
        >
            <TextField
                name='title'
                error={errors.title !== undefined}
                helperText={errors.title}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.title}
                label={FIELDS['title']}
                className={classes.textField}
                InputProps={{
                    readOnly: !editMode,
                    disableUnderline: !editMode
                }}
            />
            {editMode && <div className={classes.divider} />}
            <TextField
                name='body'
                error={errors.body !== undefined}
                helperText={errors.body}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.body}
                label={FIELDS['body']}
                className={classes.textField}
                InputProps={{
                    readOnly: !editMode,
                    disableUnderline: !editMode
                }}
                multiline={true}
            />
            {editMode && <div className={classes.divider} />}
            <FormControlLabel
                control={
                    <Checkbox
                        disabled={!editMode}
                        checked={values.important}
                        onChange={handleCheckBox}
                        name='important'
                    />
                }
                label={FIELDS['important']}
            />
            {editMode && <div className={classes.divider} />}
            <Autocomplete
                multiple
                autoComplete
                id="topics"
                disabled={!editMode}
                onChange={(e, value) => handleChange({ target: { name: 'topics', value } })}
                loading={loadingTopics}
                loadingText="Buscando Tópicos..."
                noOptionsText='No hay Tópicos'
                options={allTopics}
                value={values.topics}
                getOptionSelected={(option, value) => option._id === value._id}
                getOptionLabel={(option) => option.title}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        error={errors.topics !== undefined}
                        helperText={errors.topics}
                        label={FIELDS['topics']}
                    />
                )}
            />
        </FormLayout>
    );
};

export default withStyles(styles)(NewsFormComponent);