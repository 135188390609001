
const NAME_REGEX = /^[\p{L} ]+$/ui;

export default (values) => {
    let errors = {};

    if (!values.name) {
        errors.name = "Complete el título";
    } else if (!NAME_REGEX.test(values.name)) {
        errors.name = "El nombre sólo puede contener letras o espacios"
    }

    return errors;
}