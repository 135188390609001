import React, { Component } from 'react';
import { withTheme } from '@material-ui/core';
import { handleResponse, get, swal500 } from '../../../utils/network';
import { TableComponent } from "../../common";
import COLUMNS from "./columns";

const MESSAGES = {
    created: 'La actividad ha sido creada correctamente',
    edited: 'La actividad ha sido editada correctamente',
    deleted: 'La actividad será eliminada en el próximo período de cobro'
};

class ActivitiesTableContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            page: 0,
            maxPageFetched: 0,
            editing: false,
            activities: [],
            activitiesCount: 0,
            loading: true,
            updating: false,
            successMessage: ''
        }
    }

    componentDidMount() {
        this.getActivities();
        const msgKey = this.props.location.state?.msg;
        if (msgKey) {
            const msg = MESSAGES[msgKey];
            msg && this.setState({ successMessage: msg });
        }
    };

    getActivities = () => {
        get('activities?page=0')
            .then(res => handleResponse(res, this.props))
            .then(parsed => {
                
                this.setState({
                    activities: parsed.message.activities,
                    activitiesCount: parsed.message.count,
                    page: 0,
                    maxPageFetched: 0,
                    loading: false,
                    updating: false
                })
            }
            )
            .catch(err => {
                this.setState({ loading: false, updating: false });
                swal500(err);
            });
    };

    showMore = (page) => {
        this.setState(
            { updating: true },
            () => {
                get('activities?page=' + page)
                    .then(res => handleResponse(res, this.props))
                    .then(parsed =>
                        this.setState(prevState => ({
                            activities: prevState.activities.concat(parsed.message.activities),
                            maxPageFetched: page,
                            loading: false,
                            updating: false
                        }))
                    )
                    .catch(err => {
                        this.setState({ loading: false, updating: false });
                        swal500(err);
                    });
            }
        )
    };

    handleChangePage = (event, newPage) => {
        this.setState(
            { page: newPage },
            () => {
                window.scrollTo(0, 0);
                if (newPage > this.state.maxPageFetched) {
                    this.showMore(newPage);
                }
            }
        );
    };

    onCloseToast = (toastKey) => () => this.setState({ [toastKey]: '' });

    handleOpenForm = id => id ? this.props.history.push('/home/activities/' + id) : this.props.history.push('/home/activities/new');

    setDeletable = () => false //Since we dont get from the BE if its editable or not just dont allow deletion

    render() {
        return (
            <TableComponent
                data={this.state}
                path="/home/activities/"
                newMessage="Nueva Actividad"
                columns={COLUMNS}
                dataKey="activities"
                handleOpenForm={this.handleOpenForm}
                handleChangePage={this.handleChangePage}
                onCloseToast={this.onCloseToast}
                successMessage={this.state.successMessage}
                setDeletable={this.setDeletable}
            />
        )
    }
}

export default withTheme(ActivitiesTableContainer);
