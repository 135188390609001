export const AllPermissions = {
    'all': '5ee8d23c798dc64752c2cf01',
    'viewUsers': '5ee8d282798dc64752c2cf02',
    'manageUsers': '5ee91401fd71adae46a45bfc',
    'viewRoles': '5ee91c3afd71adae46a45bfe',
    'manageRoles': '5ee915b8fd71adae46a45bfd',
    'viewPermissions': '5eea67579873c743a0a7f5da',
    'manageOthersSessions': '5efb9e9af62305f8d0153890',
    'listTopics': '5f0d3dd17cffac8deabc6eeb',
    'manageTopics': '5f1508461452b1385b7fc06f',
    'subscribeToTopics': '5f0e95a141b0e4b7d69d64d4',
    'listNews': '5f0e1d13e57f3aca9b98f9ad',
    'manageNews': '5f1a6d562169484c222ef5cf',
    'increaseNewsRead': '5f7fef8cc6ac87762aff628c',
    'manageBranches': '5f065be605abe76486cfb3be',
    'manageEvents': '5f1e5bfc36d2f29273c2f9a8',
    'manageForeignContent': '5f29ef1eb37bf5720d47734e',
    'listMemberships': '5f305f5ae6c494ac9ece900f',
    'manageMemberships': '5f2f942ebf2ababd2160265e',
    'viewForeignMemberships': '5f31d0fc654364aaab07f953',
    'subscribeUsersToMemberships': '5f35dfd9cdca659ac8e8b570',
    'viewForeignTransactions': '5f36bddfcb1a9a3c3341efa4',
    'listFAQs': '5f49ecf104ac8ac1c9a6ade8',
    'manageFAQs': '5f4ab5b90ad12f50066c4e47',
    'manageFacilities': '5f5af6c5f926f4f0ffe54a65',
    'manageReservations': '5f5a5078bcd01e1805060b29',
    'joinReservations': '5f642e13675267b3e68b84bb',
    'manageActivities': '5f6d5f72eab4422a7ccdfe94',
    'viewActivityDetail': '5f6fe946b82853a92273d1da',
    'manageActivityEnrollments': '5f702429b82853a92273d1db',
    'manageDisciplines': '5f6ffc0305aacfa4e694987f',
    'manageSportTeams': '5f822f2e1eefff489ebf831d',
    'manageTournaments': '5f8292fb6e12cff50aa71594',
    'manageMatches': '5f8668ec89f588d0c33dd6ac',
    'listAndConfirmInvitees': '5fadf37360cbda245fbcadc1',
    'manageForeignInvitees': '5f98cf6de02321c1748c25b3',
    'viewReports': '5fb6d7e21bd411644a3d9782'
};
