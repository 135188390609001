import React, { useState, useEffect } from 'react';
import { handleResponse, post, patch, swal500, del, get } from '../../../utils/network';
import { useFormValidation } from "../../common";
import validateSportTeams from './validator';
import * as Swal from "sweetalert2";
import SportTeamsFormComponent from './SportTeamsFormComponent';
import { isMongoId } from '../../../utils/utils';

export const FIELDS = {
    name: "Nombre",
    discipline: "Disciplina",
    players: "Jugadores"
};

const INITIAL_STATE = {
    _id: '',
    name: '',
    discipline: null,
    players: [],
    newPlayer: null
};

const SportsTeamsFormContainer = (props) => {
    const [data, setData] = useState({});
    const [editMode, setEditMode] = useState(props.location.editMode || false);
    const [loading, setLoading] = useState(true);
    const [submitting, setSubmitting] = useState(false);

    const {
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        errors,
        setErrors,
        resetValues,
        mergeErrors,
        runValidations,
        handleChanges
    } = useFormValidation(INITIAL_STATE, values => validateSportTeams(values));
    const isNewSportTeam = Object.entries(data).length === 0;

    const getChangedValues = values => {
        const changedValues = [];
        Object.keys(values).forEach(key => {
            data[key] !== values[key] && changedValues.push(key)
        });

        return changedValues.filter(v => v !== 'created');
    };

    const handle400 = err => {
        err.text().then(error => {
            let errorObject = JSON.parse(error);
            let errors = {};
            errorObject.fields.forEach(field => {
                if(field.name === "sportTeam")
                {
                    errors["name"] = field.message;    
                    errors["discipline"] = field.message;    
                    return;
                }
                const regex = new RegExp(field.name);
                errors[field.name] = field.message.replace(regex, FIELDS[field.name]);
            });
            setErrors(errors);
        });
        return Promise.reject(400);
    };

    const handleClose = () => props.history.push('/home/sportteams');

    useEffect(() => {
        const loadSportTeam = (id) => {
            return get('sportteams/' + id)
                .then(res => handleResponse(res, props))
                .then(parsed => parsed.message ? Promise.resolve(parsed.message) : Promise.reject("Error en la respuesta"))
                .catch(err => {
                    setLoading(false);
                    swal500(err);
                });
        };

        if (props.match.params.id === 'new') {
            setEditMode(true);
            setLoading(false);
            return;
        } else if(isMongoId(props.match.params.id)) {

            const sportTeamID = props.match.params.id;

            loadSportTeam(sportTeamID)
            .then(sportteam => {
                const stateKeys = Object.keys(INITIAL_STATE);
                const validKeys = {}
            
                //copy only valid fields
                stateKeys.forEach(k => {
                    if(sportteam.hasOwnProperty(k))
                        validKeys[k] = sportteam[k]
                });

                setData(validKeys)
                resetValues(validKeys);
                setLoading(false);
            })
        } else 
            handleClose();
    }, [props, resetValues]);


    const handleDelete = () => {
        Swal.fire({
            title: "Eliminar Seleccionado",
            text: `¿Estás seguro de que querés eliminar el seleccionado '${values.name}'?`,
            icon: 'warning',
            showCancelButton: true,
            cancelButtonColor: '#4eaaf3', //TODO: theme.palette.primary.main
            confirmButtonColor: "#FF0000", //TODO: theme.palette.state.failure
            cancelButtonText: 'No, conservar',
            confirmButtonText: 'Si, estoy seguro',
            showLoaderOnConfirm: true,
            preConfirm: () => {
                Swal.update({ showCancelButton: false })
                return del(`sportteams/${props.match.params.id}`)
                    .then(res => handleResponse(res, props))
                    .then(result =>
                        props.history.push({
                            pathname: '/home/sportteams',
                            state: { msg: 'deleted' },
                        })
                    )
                    .catch(err => {
                        swal500(err);
                    });
            }
        });
    };

    const handleResult = (e) => {
        
        const formValid = handleSubmit(e);
        if (formValid) {
            setSubmitting(true);
            if (isNewSportTeam) {

                const body = {
                    name: values.name,
                    discipline: values.discipline._id,
                    players: values.players.map(p => p._id)
                }

                post('sportteams', body)
                    .then(res => handleResponse(res, props, [{ status: 400, method: handle400 }]))
                    .then(res => {
                        setSubmitting(false);
                        props.history.push({
                            pathname: '/home/sportteams',
                            state: { msg: 'created' },
                        });
                    })
                    .catch(err => {
                        setSubmitting(false);
                        if (err !== 400) {
                            swal500(err);
                        }
                    });
            } else {
                const changedValues = getChangedValues(values);
                if (changedValues.length === 0) {
                    props.history.push('/home/sportteams');
                    return;
                }

                const body = {}

                changedValues.forEach(value => {
                    if(value === "discipline")
                        Object.assign(body, { [value]: values[value]._id });
                    else if(value === "players")                        
                        Object.assign(body, { [value]: values[value].map(p => p._id) });
                    else
                        Object.assign(body, { [value]: values[value] });
                });
                
                patch(`sportteams/${data._id}`, body)
                    .then(res => handleResponse(res, props, [{ status: 400, method: handle400 }]))
                    .then(res => {
                        setSubmitting(false);
                        props.history.push({
                            pathname: '/home/sportteams',
                            state: { msg: 'edited' },
                        });
                    })
                    .catch(err => {
                        setSubmitting(false);
                        if (err !== 400) {
                            swal500(err);
                        }
                    });
            }
        }
    };

    return (
        <SportTeamsFormComponent
            errors={errors}
            handleChange={handleChange}
            handleBlur={handleBlur}
            handleClose={handleClose}
            handleResult={handleResult}
            handleDelete={handleDelete}
            loading={loading}
            submitting={submitting}
            values={values}
            editMode={editMode}
            setEditMode={setEditMode}
            isNewSportTeam={isNewSportTeam}
            mergeErrors={mergeErrors}
            runValidations={runValidations}
            handleChanges={handleChanges}
        />
    );
};

export default SportsTeamsFormContainer;