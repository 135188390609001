export default [
    {
        id: 'firstName',
        label: 'Nombre/s',
        minWidth: 100,
        hidden: {
            xsDown: true
        }
    },
    {
        id: 'lastName',
        label: 'Apellido/s',
        minWidth: 100,
        hidden: {
            xsDown: true
        }
    },
    {
        id: 'isPendingCancellation',
        label: 'Solicitud de baja',
        minWidth: 100,
        hidden: {
            xsDown: true
        }
    }
];
