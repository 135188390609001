export default () => ({
  topActionsContainer: {
    display: 'flex',
    justifyContent: 'space-around',
    alignSelf: 'center',
    marginRight: 15
  },
  textField: {
    marginTop: 13
  },
  comboBox: {
    marginTop: 13
  },
  divider: {
    height: 20
  },
  hidden: {
    display: "none"
  },
  leaflet: {
    width: "100%", 
    height: "300px",
    marginTop: "30px"
  }
});
