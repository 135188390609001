export default (theme) => ({
  topActionsContainer: {
    display: 'flex',
    justifyContent: 'space-around',
    alignSelf: 'center',
    marginRight: 15
  },
  textField: {
    marginTop: 13
  },
  comboBox: {
    marginTop: 13
  },
  divider: {
    height: 20
  },
  image: {
    maxWidth: theme.spacing(40),
    maxHeight: theme.spacing(40),
  },
  imageButton: {
    margin: 10,
    alignSelf: 'center',
  },
  inputFile: {
    display: 'none',
  },
  imageCardActions: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  imageCardLabel: {
    display: 'flex',
    alignItems: 'center',
  },
  imageContainer: {
    minHeight: theme.spacing(20),
    display:"flex",
    flexDirection: "column", 
    justifyContent: "center",
    alignItems: "center"
  },
  imageLabel: {
    color: "#0000008A"
  },
  imageLabelError: {
    color: "#f44336"
  }
});

