export default [
    {
        id: 'typeName',
        label: 'Tipo de Abono',
        minWidth: 100,
        hidden: {
            xsDown: true
        }
    },
    {
        id: 'description',
        label: 'Descripción',
        minWidth: 100,
        hidden: {
            xsDown: true
        }
    },
    {
        id: 'price',
        label: 'Precio',
        minWidth: 50,
        hidden: {
            xsDown: true
        }
    },
];
