import React, { useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';
import { ApiAutocomplete, useFormValidation } from "../../common";
import validateParticipant from './validator';
import { handleResponse, patch, swal500 } from '../../../utils/network';

const INITIAL_STATE = {
    participant: null
}

const NewParticipantDialog = (props) => {
    
    const {
        handleChange,
        handleChanges,
        runValidations,
        values,
        errors,
        setErrors
    } = useFormValidation(INITIAL_STATE, values => validateParticipant(values));

    const [submitting, setSubmitting] = useState(false);

    const doPost = () => {
        const { activityId } = props;

        const handle400 = err => {
            err.text().then(error => {
                let errorObject = JSON.parse(error);
                let errors = {};
                errors["participant"] = errorObject.fields[0].message;
                setErrors(errors);
            });
            return Promise.reject(400);
        };

        patch(`activities/${activityId}/enroll`, {user_id: values.participant._id})
            .then(res => handleResponse(res, props, [{status: 400, method: handle400}]))
            .then(result =>{

                handleChanges({
                    participant: null
                })
                setErrors({})
             
                props.onNewParticipant();
                
            })
            .catch(err => {
                if(err !== 400)
                    swal500(err);
            })
            .then(() => {
                setSubmitting(false)
            });
    }

    const validateAndSend = () => {
        runValidations();

        if(!values.participant)
            return;

        setSubmitting(true);
        doPost();
    }

    return (
        <Dialog open={props.dialogOpen} onClose={props.closeDialog} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">Nuevo participante</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Estás agregando un nuevo participante a la actividad
                </DialogContentText>

                <ApiAutocomplete
                    error={errors.participant}
                    handleChange={handleChange}
                    label={(user) => `${user.firstName} ${user.lastName}`}
                    name='participant'
                    path='users?email=like:'
                    title={"Participante"}
                    value={values.participant}
                    noOptionsText="No hay socios con ese correo electrónico"
                    filterLabel={(value) => value.email}
                    dataField="data"
                    disabled={submitting}
                />


            </DialogContent>
            <DialogActions>
                <Button onClick={props.closeDialog} color="primary">
                    Cancelar
                </Button>
                <Button onClick={validateAndSend} color="primary" disabled={submitting}>
                    Guardar
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default NewParticipantDialog;