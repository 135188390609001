import React from "react";
import { IconButton, TextField, withStyles } from "@material-ui/core";
import { Create as CreateIcon, Delete as DeleteIcon } from '@material-ui/icons';
import styles from "./styles";
import { FIELDS } from "./EventsFormContainer";
import { FormLayout } from "../../common";
import { Autocomplete } from '@material-ui/lab';
import moment from 'moment';
import 'moment/locale/es';

const EventsFormComponent = (props) => {
    const {
        classes,
        errors,
        handleChange,
        handleDateChange,
        handleBlur,
        handleClose,
        handleResult,
        handleDelete,
        submitting,
        loading,
        loadingBranches,
        loadingTopics,
        values,
        allBranches,
        allTopics,
        editMode,
        setEditMode,
        isNewEvent,
    } = props;

    const renderActions = () => (
        <div className={classes.topActionsContainer}>
            {!editMode && (
                <IconButton onClick={() => setEditMode(true)}>
                    <CreateIcon />
                </IconButton>
            )}
            {!isNewEvent &&
                <IconButton onClick={handleDelete}>
                    <DeleteIcon />
                </IconButton>
            }
        </div>
    );

    return (
        <FormLayout
            handleClose={handleClose}
            renderActions={renderActions}
            loading={loading}
            editMode={editMode}
            submitting={submitting}
            handleSubmit={handleResult}
            isNew={isNewEvent}
            dataKey="Evento"
        >
            <TextField
                name='event'
                error={errors.event !== undefined}
                helperText={errors.event}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.event}
                label={FIELDS['event']}
                className={classes.textField}
                InputProps={{
                    readOnly: !editMode,
                    disableUnderline: !editMode
                }}
            />
            {editMode && <div className={classes.divider} />}
            <TextField
                name='description'
                error={errors.description !== undefined}
                helperText={errors.description}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.description}
                label={FIELDS['description']}
                className={classes.textField}
                InputProps={{
                    readOnly: !editMode,
                    disableUnderline: !editMode
                }}
                multiline={true}
            />
            {editMode && <div className={classes.divider} />}
            <TextField
                name="eventDate"
                label="Fecha"
                type="datetime-local"
                disabled={!editMode}
                error={errors.eventDate !== undefined}
                helperText={errors.eventDate}
                value={moment(values.eventDate).format('YYYY-MM-DDTHH:mm')}
                onChange={handleDateChange}
                InputLabelProps={{
                    shrink: true,
                }}
            />
            {editMode && <div className={classes.divider} />}
            <Autocomplete
                autoComplete
                id="branch"
                disabled={!editMode}
                onChange={(e, value) => handleChange({ target: { name: 'branch', value } })}
                loading={loadingBranches}
                loadingText="Buscando Sedes..."
                noOptionsText='No hay Sedes'
                options={allBranches}
                value={values.branch}
                getOptionSelected={(option, value) => option._id === value._id}
                getOptionLabel={(option) => option.name}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        error={errors.branch !== undefined}
                        helperText={errors.branch}
                        label={FIELDS['branch']}
                    />
                )}
            />
            {editMode && <div className={classes.divider} />}
            <Autocomplete
                multiple
                autoComplete
                id="topics"
                disabled={!editMode}
                onChange={(e, value) => handleChange({ target: { name: 'topics', value } })}
                loading={loadingTopics}
                loadingText="Buscando Tópicos..."
                noOptionsText='No hay Tópicos'
                options={allTopics}
                value={values.topics}
                getOptionSelected={(option, value) => option._id === value._id}
                getOptionLabel={(option) => option.title}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        error={errors.topics !== undefined}
                        helperText={errors.topics}
                        label={FIELDS['topics']}
                    />
                )}
            />
        </FormLayout>
    );
};

export default withStyles(styles)(EventsFormComponent);