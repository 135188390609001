import React from 'react';
import { Layout } from '../components/common';
import { Route, Redirect, Switch } from 'react-router-dom';
import UserTableContainer from '../components/Users/UsersTable/UserTableContainer';
import UserFormContainer from '../components/Users/UsersForm/UserFormContainer';
import RolesTableContainer from "../components/Roles/RolesTable/RolesTableContainer";
import RolesFormContainer from "../components/Roles/RolesForm/RolesFormContainer";
import SessionsList from "../components/Sessions/SessionsList";
import ProfileFormContainer from "../components/Profile/ProfileFormContainer";
import { getCookie } from '../utils/utils';
import TopicsContainer from '../components/Topics/TopicsContainer';
import TopicsFormContainer from '../components/Topics/TopicsForm/TopicsFormContainer';
import NewsTableContainer from '../components/News/NewsTable/NewsTableContainer';
import NewsFormContainer from '../components/News/NewsForm/NewsFormContainer';
import EventsContainer from '../components/Events/EventsContainer'
import EventsFormContainer from '../components/Events/EventsForm/EventsFormContainer'
import BranchesTableContainer from '../components/Branches/BranchesTable/BranchesTableContainer';
import BranchesFormContainer from '../components/Branches/BranchesForm/BranchesFormContainer';
import MembershipsTableContainer from '../components/Memberships/MembershipsTable/MembershipsTableContainer';
import MembershipsFormContainer from '../components/Memberships/MembershipsForm/MembershipsFormContainer';
import MembershipTypesContainer from '../components/MembershipTypes/MembershipTypesContainer';
import MembershipTypesFormContainer from '../components/MembershipTypes/MembershipTypesForm/MembershipTypesFormContainer';
import FAQsContainer from '../components/FAQs/FAQsContainer'
import FAQsFormContainer from '../components/FAQs/FAQsForm/FAQsFormContainer';
import FacilitiesTableContainer from '../components/Facilities/FacilitiesTable/FacilitiesTableContainer';
import FacilitiesFormContainer from '../components/Facilities/FacilitiesForm/FacilitiesFormContainer';
import ReservationsContainer from '../components/Reservations/ReservationsTable/ReservationsContainer';
import ActivitiesTableContainer from '../components/Activities/ActivitiesTable/ActivitiesTableContainer';
import DisciplinesTableContainer from '../components/Disciplines/DisciplinesTable/DisciplinesTableContainer';
import DisciplinesFormContainer from '../components/Disciplines/DisciplinesForm/DisciplinesFormContainer';
import ActivitiesFormContainer from '../components/Activities/ActivitiesForm/ActivitiesFormContainer';
import ActivityParticipantsTableContainer from '../components/Activities/ParticipantsCRUD/ActivityParticipantsTableContainer';
import SportTeamsTableContainer from '../components/SportTeams/SportTeamsTable/SportTeamsTableContainer';
import SportsTeamsFormContainer from '../components/SportTeams/SportTeamsForm/SportTeamsFormContainer';
import TournamentsTableContainer from '../components/Tournaments/TournamentsTable/TournamentsTableContainer';
import TournamentFormContainer from '../components/Tournaments/TournamentForm/TournamentFormContainer';
import MatchesTableContainer from '../components/Matches/MatchesTable/MatchesTableContainer';
import MatchFormContainer from '../components/Matches/MatchForm/MatchFormContainer';
import InviteesTableContainer from '../components/Invitees/InviteesTable/InviteesTableContainer';
import InviteesFormContainer from '../components/Invitees/InviteesForm/InviteesFormContainer';
import ReportsContainer from '../components/Reports/ReportsContainer';

export default function Home(props) {
    const isLogged = getCookie("isLogged");
    return (
        !isLogged
            ? <Redirect from='/home/*' to='/login' />
            : <Layout history={props.history}>
            <Switch>
                <Route exact path='/home/users' component={UserTableContainer} />
                <Route exact path='/home/users/:id' component={UserFormContainer} />
                <Route exact path='/home/roles' component={RolesTableContainer} />
                <Route exact path='/home/roles/:id' component={RolesFormContainer} />
                <Route exact path='/home/sessions' component={SessionsList} />
                <Route exact path='/home/profile' component={ProfileFormContainer} />
                <Route exact path='/home/topics' component={TopicsContainer} />
                <Route exact path='/home/topics/:id' component={TopicsFormContainer} />
                <Route exact path='/home/news' component={NewsTableContainer} />
                <Route exact path='/home/news/:id' component={NewsFormContainer} />
                <Route exact path='/home/events' component={EventsContainer} />
                <Route exact path='/home/events/:id' component={EventsFormContainer} />
                <Route exact path='/home/branches' component={BranchesTableContainer} />
                <Route exact path='/home/branches/:id' component={BranchesFormContainer} />
                <Route exact path='/home/memberships' component={MembershipsTableContainer} />
                <Route exact path='/home/memberships/:id' component={MembershipsFormContainer} />
                <Route exact path='/home/membershipTypes' component={MembershipTypesContainer} />
                <Route exact path='/home/membershipTypes/:id' component={MembershipTypesFormContainer} />
                <Route exact path='/home/faqs' component={FAQsContainer} />
                <Route exact path='/home/faqs/:id' component={FAQsFormContainer} />
                <Route exact path='/home/facilities' component={FacilitiesTableContainer} />
                <Route exact path='/home/facilities/:id' component={FacilitiesFormContainer} />
                <Route exact path='/home/facilities/:facilityId/reservations' component={ReservationsContainer} />
                <Route exact path='/home/activities' component={ActivitiesTableContainer} />
                <Route exact path='/home/activities/:id' component={ActivitiesFormContainer} />
                <Route exact path='/home/activities/:id/participants' component={ActivityParticipantsTableContainer} />
                <Route exact path='/home/disciplines' component={DisciplinesTableContainer} />
                <Route exact path='/home/disciplines/:id' component={DisciplinesFormContainer} />
                <Route exact path='/home/sportteams' component={SportTeamsTableContainer} />
                <Route exact path='/home/sportteams/:id' component={SportsTeamsFormContainer} />
                <Route exact path='/home/sportteams/:id/tournaments' component={TournamentsTableContainer} />
                <Route exact path='/home/sportteams/:sportTeamId/tournaments/:id' component={TournamentFormContainer} />
                <Route exact path='/home/sportteams/:sportTeamId/tournaments/:id/matches' component={MatchesTableContainer} />
                <Route exact path='/home/sportteams/:sportTeamId/tournaments/:tournamentId/matches/:id' component={MatchFormContainer} />
                <Route exact path='/home/invitees' component={InviteesTableContainer} />
                <Route exact path='/home/invitees/:id' component={InviteesFormContainer} />
                <Route exact path='/home/reports' component={ReportsContainer} />
                <Redirect from="/home" to={JSON.parse(getCookie('routeOptions'))[0].path} exact />
                <Redirect from='/home/*' to='/404' />
            </Switch>
        </Layout>
    )
}
