import React, { Component } from 'react';
import { withTheme } from '@material-ui/core';
import * as Swal from 'sweetalert2';
import { handleResponse, get, del, swal500 } from '../../../utils/network';
import { TableComponent } from "../../common";
import COLUMNS from "./columns";
import { isMongoId } from '../../../utils/utils';
import moment from 'moment';
import 'moment/locale/es';

const MESSAGES = {
    created: 'El torneo ha sido creado correctamente',
    edited: 'El torneo ha sido editado correctamente',
    deleted: 'El torneo ha sido eliminado correctamente'
};

class MatchesTableContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            page: 0,
            maxPageFetched: 0,
            editing: false,
            sportTeamId: null,
            tournamentId: null,
            matches: [],
            matchesCount: 0,
            loading: true,
            updating: false,
            successMessage: ''
        }
    }

    handleClose = () => this.props.history.push(`/home/sportteams/${this.state.sportTeamId}/tournaments/${this.state.tournamentId}`);

    componentDidMount() {
        const { sportTeamId, id } = this.props.match.params;

        if (!((id && isMongoId(id)) && (sportTeamId && isMongoId(sportTeamId)))) {
            this.handleClose();
            return;
        }

        this.setState({ sportTeamId, tournamentId: id });
        this.getMatches(id);

        const msgKey = this.props.location.state?.msg;
        if (msgKey) {
            const msg = MESSAGES[msgKey];
            msg && this.setState({ successMessage: msg });
        }
    };

    formatMatches = (matches) => {
        return matches.map(match => {
            const matchCopy = { ...match };
            matchCopy.dateFormatted = moment(matchCopy.date).format('DD/MM/YYYY HH:mm');
            return matchCopy;
        });
    }

    getMatches = (id) => {
        get(`tournaments/${id}/matches?page=0`)
            .then(res => handleResponse(res, this.props))
            .then(parsed => {
                this.setState({
                    matches: this.formatMatches(parsed.message.data),
                    matchesCount: parsed.message.count,
                    page: 0,
                    maxPageFetched: 0,
                    loading: false,
                    updating: false
                })
            })
            .catch(err => {
                this.setState({ loading: false, updating: false });
                swal500(err);
                this.handleClose();
            });
    };

    showMore = (page) => {
        this.setState(
            { updating: true },
            () => {
                get(`tournaments/${this.state.tournamentId}/matches?page=${page}`)
                    .then(res => handleResponse(res, this.props))
                    .then(parsed =>
                        this.setState(prevState => ({
                            matches: prevState.matches.concat(this.formatMatches(parsed.message.data)),
                            maxPageFetched: page,
                            loading: false,
                            updating: false
                        }))
                    )
                    .catch(err => {
                        this.setState({ loading: false, updating: false });
                        swal500(err);
                    });
            }
        )
    };

    handleChangePage = (event, newPage) => {
        this.setState(
            { page: newPage },
            () => {
                window.scrollTo(0, 0);
                if (newPage > this.state.maxPageFetched) {
                    this.showMore(newPage);
                }
            }
        );
    };

    onCloseToast = (toastKey) => () => this.setState({ [toastKey]: '' });

    handleOpenForm = (id, stateToSend) => id ?
        this.props.history.push({
            pathname: `/home/sportteams/${this.state.sportTeamId}/tournaments/${this.state.tournamentId}/matches/${id}`,
            state: stateToSend
        })
        :
        this.props.history.push(`/home/sportteams/${this.state.sportTeamId}/tournaments/${this.state.tournamentId}/matches/new`);

    handleDelete = matchesId => {
        const match = this.state.matches.find(u => u._id === matchesId);
        const { palette } = this.props.theme;
        Swal.fire({
            title: "Eliminar Partido",
            text: `¿Estás seguro de que querés eliminar el partido '${match.instance}'?`,
            icon: 'warning',
            showCancelButton: true,
            cancelButtonColor: palette.primary.main,
            confirmButtonColor: palette.state.failure,
            cancelButtonText: 'No, conservar partido',
            confirmButtonText: 'Si, estoy seguro',
        }).then(result => {
            if (result.value) {
                this.setState(
                    { updating: true },
                    () => {
                        del(`matches/${match._id}`)
                            .then(res => handleResponse(res, this.props))
                            .then(result =>
                                this.setState(
                                    (prevState) => ({
                                        matches: prevState.matches.filter(r => r._id !== matchesId),
                                        matchesCount: prevState.matchesCount - 1,
                                        updating: false,
                                        successMessage: MESSAGES.deleted
                                    })
                                ))
                            .then(res => this.getMatches(this.state.tournamentId))
                            .catch(err => {
                                swal500(err);
                                this.setState({ updating: false });
                            });
                    }
                );
            }
        })
    };

    navStateSelector = ({ _id, date, instance, rivalName, score }) =>
        ({ _id, date, instance, rivalName, score, tournament: this.state.tournamentId })

    render() {
        return (
            <TableComponent
                data={this.state}
                path={`/home/sportteams/${this.state.sportTeamId}/tournaments/${this.state.tournamentId}/matches/`}
                newMessage="Nuevo Partido"
                columns={COLUMNS}
                dataKey="matches"
                handleOpenForm={this.handleOpenForm}
                handleDelete={this.handleDelete}
                handleChangePage={this.handleChangePage}
                onCloseToast={this.onCloseToast}
                successMessage={this.state.successMessage}
                navStateMapper={this.navStateSelector}
            />
        )
    }
}

export default withTheme(MatchesTableContainer);
