import React from "react";
import { IconButton, TextField, withStyles } from "@material-ui/core";
import { Create as CreateIcon, Delete as DeleteIcon } from '@material-ui/icons';
import styles from "./styles";
import { FIELDS } from "./UserFormContainer";
import { FormLayout, PermissionsSelector, CustomPassword } from "../../common";
import { Autocomplete } from '@material-ui/lab';
import { getCookie } from "../../../utils/utils";

const UserFormComponent = (props) => {
    const {
        classes,
        errors,
        handleChange,
        handleBlur,
        handleClose,
        handleResult,
        handleDelete,
        handleDateChange,
        submitting,
        loading,
        loadingPermissions,
        loadingRoles,
        values,
        editMode,
        setEditMode,
        isNewUser,
        permissions,
        allRoles,
        handleChangePermission,
        handleChangeCategory
    } = props;

    const isCurrentUser = getCookie("userId") === values?._id;
    const renderActions = () => (
        <div className={classes.topActionsContainer}>
            {!editMode && (
                <IconButton onClick={() => setEditMode(true)}>
                    <CreateIcon />
                </IconButton>
            )}
            {!isNewUser &&
                <IconButton onClick={handleDelete}>
                    <DeleteIcon />
                </IconButton>
            }
        </div>
    );

    return (
        <FormLayout
            handleClose={handleClose}
            renderActions={renderActions}
            loading={loading}
            editMode={editMode}
            submitting={submitting}
            handleSubmit={handleResult}
            isNew={isNewUser}
            dataKey="Usuario"
        >
            <TextField
                name='firstName'
                error={errors.firstName !== undefined}
                helperText={errors.firstName}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.firstName}
                label={FIELDS['firstName']}
                className={classes.textField}
                InputProps={{
                    readOnly: !editMode,
                    disableUnderline: !editMode
                }}
            />
            {editMode && <div className={classes.divider} />}
            <TextField
                name='lastName'
                error={errors.lastName !== undefined}
                helperText={errors.lastName}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.lastName}
                label={FIELDS['lastName']}
                className={classes.textField}
                InputProps={{
                    readOnly: !editMode,
                    disableUnderline: !editMode
                }}
            />
            {editMode && <div className={classes.divider} />}
            <TextField
                name="birthdate"
                label={FIELDS['birthdate']}
                type="date"
                // className={classes.date}
                error={errors.birthdate !== undefined}
                helperText={errors.birthdate}
                margin="dense"
                disabled={!editMode || submitting}
                FormHelperTextProps={{ margin: 'dense' }}
                defaultValue={values.birthdate && values.birthdate.format('YYYY-MM-DD')}
                onChange={handleDateChange}
                InputLabelProps={{
                    shrink: true,
                }}
            />
            {editMode && <div className={classes.divider} />}
            <TextField
                name='memberNumber'
                error={errors.memberNumber}
                helperText={errors.memberNumber}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.memberNumber}
                label={FIELDS['memberNumber']}
                type="number"
                disabled={!editMode || submitting}
                className={classes.textField}
                InputProps={{
                    readOnly: !editMode || submitting,
                    disableUnderline: !editMode
                }}
            />
            {editMode && <div className={classes.divider} />}
            <TextField
                name='email'
                error={errors.email !== undefined}
                helperText={errors.email}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.email}
                label={FIELDS['email']}
                className={classes.textField}
                InputProps={{
                    readOnly: !editMode,
                    disableUnderline: !editMode
                }}
            />
            {editMode && <div className={classes.divider} />}
            <TextField
                name='phoneNumber'
                error={errors.phoneNumber !== undefined}
                helperText={errors.phoneNumber}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.phoneNumber}
                label={FIELDS['phoneNumber']}
                type="number"
                disabled={!editMode || submitting}
                className={classes.textField}
                InputProps={{
                    readOnly: !editMode,
                    disableUnderline: !editMode
                }}
            />
            {editMode && isNewUser && <div className={classes.divider} />}
            {
                isNewUser &&
                <CustomPassword
                    name='password'
                    error={errors.password}
                    handleChange={handleChange}
                    onBlur={handleBlur}
                    value={values.password}
                    label={FIELDS['password']}
                    className={classes.textField}
                    hideStartAdornment
                    InputProps={{
                        autoComplete: 'new-password'
                    }}
                />
            }
            {editMode && <div className={classes.divider} />}
            {
                editMode && !isCurrentUser &&
                <Autocomplete
                    autoComplete
                    id="role"
                    disabled={!editMode}
                    onChange={(e, value) => handleChange({ target: { name: 'role', value } })}
                    loading={loadingRoles}
                    loadingText="Buscando Roles..."
                    noOptionsText='No hay Roles'
                    options={allRoles}
                    value={values.role}
                    getOptionSelected={(option, value) => option._id === value._id}
                    getOptionLabel={(option) => option.role}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label="Rol"
                            margin="normal"
                            error={errors.role !== undefined}
                            helperText={errors.role}
                        />
                    )}
                    
                />
            }
            {editMode && <div className={classes.divider} />}
            { !isCurrentUser ?
                <PermissionsSelector
                    permissions={permissions}
                    editMode={editMode}
                    loading={loadingPermissions}
                    handleChangePermission={handleChangePermission}
                    handleChangeCategory={handleChangeCategory}
                /> : (
                    !editMode ? (
                        <PermissionsSelector
                            permissions={permissions}
                            editMode={editMode}
                            loading={loadingPermissions}
                            handleChangePermission={handleChangePermission}
                            handleChangeCategory={handleChangeCategory}
                        />
                    ) : null
                )
            }
        </FormLayout>
    );
};

export default withStyles(styles)(UserFormComponent);