import React from "react";
import {
    Avatar, Button, Card, CircularProgress,
    Grid,
    IconButton,
    Paper, Switch, TextField, Typography,
    withStyles
} from "@material-ui/core";
import { Create as CreateIcon } from '@material-ui/icons';
import { ErrorToast, Loading, SuccessToast } from "../../components/common";
import { FIELDS } from "./ProfileFormContainer";
import styles from "./styles";

const ProfileFormComponent = (props) => {
    const {
        classes,
        errors,
        handleChange,
        handleBlur,
        handleResult,
        handleUploadImage,
        submitting,
        submittingImage,
        loading,
        values,
        editMode,
        setEditMode,
        imageUrl,
        successMessage,
        errorMessage,
        onCloseToast,
    } = props;

    const renderActions = () => (
        <div className={classes.topActionsContainer}>
            {!editMode && (
                <IconButton onClick={() => setEditMode(true)}>
                    <CreateIcon />
                </IconButton>
            )}
        </div>
    );

    const ProfileCard = () => (
        <Card elevation={0} className={classes.profileCard}>
            <Avatar
                alt="Remy Sharp"
                src={imageUrl}
                className={classes.image}
            />
            {editMode &&
                <div className={classes.profileCardActions}>
                    <input
                        accept="image/*"
                        type='file'
                        onChange={handleUploadImage}
                        id="upload-file"
                        className={classes.inputFile}
                    />
                    <label className={classes.profileCardLabel} htmlFor="upload-file">
                        {submittingImage &&
                            <CircularProgress color="primary" size={22} style={{ marginRight: 10 }} />
                        }
                        <Button
                            component='span'
                            color='primary'
                            variant='contained'
                            disabled={loading || submittingImage}
                            className={classes.profileCardButton}
                        >
                            Actualizar imagen
                        </Button>
                    </label>
                </div>
            }
        </Card>
    );

    return (
        <div>
            <Paper elevation={2} className={classes.root}>
                <div className={classes.formHeader}>
                    <div className={classes.titleContainer} >
                        <Typography style={{ fontSize: 20 }}>
                            Mi Perfil
                        </Typography>
                    </div>
                    {renderActions()}
                </div>
                {loading ? (
                    <Loading />
                ) : (
                    <div className={classes.contentContainer}>
                        <ProfileCard />
                        <form className={classes.form}>
                            <TextField
                                name='firstName'
                                error={errors.firstName !== undefined}
                                helperText={errors.firstName}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.firstName}
                                label={FIELDS['firstName']}
                                className={classes.textField}
                                InputProps={{
                                    readOnly: true,
                                    disableUnderline: true
                                }}
                            />
                            {editMode && <div className={classes.divider} />}
                            <TextField
                                name='lastName'
                                error={errors.lastName !== undefined}
                                helperText={errors.lastName}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.lastName}
                                label={FIELDS['lastName']}
                                className={classes.textField}
                                InputProps={{
                                    readOnly: true,
                                    disableUnderline: true
                                }}
                            />
                            {editMode && <div className={classes.divider} />}
                            <TextField
                                name='email'
                                error={errors.email !== undefined}
                                helperText={errors.email}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.email}
                                label={FIELDS['email']}
                                className={classes.textField}
                                InputProps={{
                                    readOnly: true,
                                    disableUnderline: true
                                }}
                            />
                            {editMode && <div className={classes.divider} />}

                            <TextField
                                name='phoneNumber'
                                error={errors.phoneNumber !== undefined}
                                helperText={errors.phoneNumber}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.phoneNumber}
                                label={FIELDS['phoneNumber']}
                                type="number"
                                className={classes.textField}
                                InputProps={{
                                    readOnly: !editMode,
                                    disableUnderline: !editMode
                                }}
                            />
                            {editMode && <div className={classes.divider} />}
                            <Typography variant="caption" display="block" className={classes.textField} color="textSecondary">
                                {FIELDS['publicPhoneNumber']}
                            </Typography>
                            <Grid direction="row" alignItems="center" spacing={1}>
                                <Typography variant="caption">Ocultar</Typography>
                                <Switch
                                    checked={values.publicPhoneNumber}
                                    defaultToggled={false}
                                    onChange={({ target }) => handleChange({ target: { name: "publicPhoneNumber", value: target.checked } })}
                                    disabled={!editMode}
                                    color="primary"
                                />
                                <Typography variant="caption">Mostrar</Typography>
                            </Grid>
                            <Typography variant="caption" display="block" gutterBottom>
                                *Tu número siempre será compartido a otros socios cuando abras eventos o te unas a eventos abiertos por ellos.
                            </Typography>
                            <div className={classes.actionsContainer}>
                                {
                                    editMode &&
                                    <>
                                        {
                                            submitting &&
                                            <CircularProgress color="primary" size={22} style={{ marginRight: 10 }} />
                                        }
                                        <Button
                                            type="submit"
                                            color="primary"
                                            variant="contained"
                                            disabled={loading || submitting}
                                            onClick={handleResult}
                                        >
                                            Confirmar
                                        </Button>
                                    </>
                                }
                            </div>
                        </form>
                    </div>
                )
                }
            </Paper >
            <SuccessToast
                open={successMessage !== ''}
                onClose={onCloseToast('successMessage')}
                text={successMessage}
            />
            <ErrorToast
                open={errorMessage !== ''}
                onClose={onCloseToast('errorMessage')}
                text={errorMessage}
            />
        </div>
    );
};

export default withStyles(styles)(ProfileFormComponent);