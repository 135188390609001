import { validateAgeFields } from "../../../utils/utils";
import { values as durationsValues } from "../durations";

export const ADDRESS = /.+(,.+){1,2}/;
export const NAME_REGEX = /^[\p{L} ]+$/ui;
const POSITIVE_NUMBER_REGEX = /^\d+([.]\d+)?$/;


export default (values) => {
    let errors = {};

    
    if(!values.type)
    {
        errors.type = "Seleccione un Tipo de Abono."
    }

    if (!values.description) {
        errors.description = "Complete la descripción";
    }

    if (!values.price) {
        errors.price = "Complete el precio";
    } else if (!POSITIVE_NUMBER_REGEX.test(values.price)) {
        errors.price = "El precio debe ser un número no negativo."
    }

    if (!values.lifelongPrice) {
        errors.lifelongPrice = "Complete el precio para vitalicios";
    } else if (!POSITIVE_NUMBER_REGEX.test(values.lifelongPrice)) {
        errors.lifelongPrice = "El precio para vitalicios debe ser un número no negativo."
    }

    validateAgeFields(values, errors);
    
    if (!values.duration) {
        errors.duration = "Seleccione una duración";
    } else if (!durationsValues().includes(values.duration)) {
        errors.duration = "Selecciona una duración válida"
    }
    
    return errors;
}