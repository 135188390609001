export default (values) => {
    let errors = {};

    if (!values.title) {
        errors.title = "Complete el título";
    }

    if (!values.body) {
        errors.body = "Complete el cuerpo";
    }

    if (!values.topics.length) {
        errors.topics = "Complete los tópicos";
    }

    return errors;
}