import { useCallback, useState } from "react";
import moment from 'moment';
import 'moment/locale/es';

function useFormValidation(initialState, validate) {
    const [values, setValues] = useState(initialState);
    const [errors, setErrors] = useState({});

    const resetError = name => setErrors(prevErrors => ({ ...prevErrors, [name]: undefined }));

    const mergeErrors = errors => setErrors(prevErrors => ({ ...prevErrors, ...errors }));

    const handleChanges = (newValues) => {
        setValues(prevValues => ({
            ...prevValues,
            ...newValues
        }));
    };

    const handleChange = event => {
        const { name, value } = event.target;
        resetError(name);
        handleChanges({ [name]: value });
    };

    const resetValues = useCallback(values => {
        setValues(values);
    }, []);

    const handleBlur = event => {
        const { name } = event.target;
        const validationErrors = validate(values);
        mergeErrors({ [name]: validationErrors[name] });
    };

    const handleCheckBox = (event) => {
        const { name, checked } = event.target;
        handleChanges({ [name]: checked });
    };

    const handleDateChange = ({ target }) => {
        const { name, value } = target;
        handleChanges({ [name]: value && moment(value) });
    }

    const runValidations = () => {
        const validationErrors = validate(values);
        setErrors(validationErrors);
    }

    const handleSubmit = event => {
        event.preventDefault();
        const validationErrors = validate(values);
        setErrors(validationErrors);
        return Object.entries(validationErrors).length === 0;
    };

    return {
        handleSubmit,
        handleChange,
        handleBlur,
        handleCheckBox,
        handleDateChange,
        values,
        errors,
        setErrors,
        mergeErrors,
        resetValues,
        handleChanges,
        runValidations
    };
}

export default useFormValidation;
