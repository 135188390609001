export const NAME_REGEX = /^[\p{L}\d ]+$/ui;
export const HEX_COLOR = /#[0-9a-zA-Z]{6}/;

export default (values) => {
    let errors = {};

    if (!values.title) {
        errors.title = "Complete el título";
    } else if (!NAME_REGEX.test(values.title)) {
        errors.title = "El titulo sólo puede contener letras, números o espacios"
    }

    if (!values.color) {
        errors.color = "Seleccione un Color";
    } else if (!HEX_COLOR.test(values.color)) {
        errors.color = "El color debe ser un código de color hexadecimal válido"
    }

    return errors;
}