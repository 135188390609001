
export const ADDRESS = /.+(,.+){1,2}/;
export const NAME_REGEX = /^[\p{L}\d ]+$/ui;
const INT_NUM_REGEX = /^\d+?$/;
const POSITIVE_NUMBER_REGEX = /^\d+([.]\d+)?$/;
const TIME_OF_DAY_24_HOURS_REGEX = /^([01][0-9]|2[0-3]):([0-5][0-9])$/;


export default (values) => {
    let errors = {};

    if (!values.name) {
        errors.name = "Complete el nombre";
    } else if (!NAME_REGEX.test(values.name)) {
        errors.name = "El nombre debe ser un nombre válido"
    }

    if (!values.description) {
        errors.description = "Complete la descripción";
    }

    if (!values.shiftDuration) {
        errors.shiftDuration = "Complete la duración del turno";
    } else if (!INT_NUM_REGEX.test(values.shiftDuration)) {
        errors.shiftDuration = "La duración del turno debe ser un número no negativo."
    }

    if (!values.branch) {
        errors.branch = "Debe elegir la sede de la instalación";
    } 

    if(typeof values.detailImage == "object" && !values.detailImage.type.includes("image/"))
    {
        errors.detailImage = "Debe cargar una imagen."
    }

    if(Array.isArray(values.openingIntervals)) {

        if(values.openingIntervals.length === 0) 
            errors.openingIntervals = "Defina al menos un intervalo horario."
    }

    if(values.newClosesAt)
    {
        if (!TIME_OF_DAY_24_HOURS_REGEX.test(values.newClosesAt)) {
            errors.newClosesAt = "El horario debe estar en formato 24 horas."
        }
    }

    if(values.newOpensAt)
    {
        if (!TIME_OF_DAY_24_HOURS_REGEX.test(values.newOpensAt)) {
            errors.newOpensAt = "El horario debe estar en formato 24 horas."
        }
    }

    if(values.newShiftPrice)
    {
        if (!POSITIVE_NUMBER_REGEX.test(values.newShiftPrice)) {
            errors.newShiftPrice = "El precio del turno debe ser un número no negativo."
        }
    }
    
    return errors;
}