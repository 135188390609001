import React from 'react'
import { Card, TextField, Grid, Fab, IconButton, Button } from '@material-ui/core'
import { withTheme, withStyles } from '@material-ui/core/styles'
import AddIcon from '@material-ui/icons/Add';
import SearchIcon from '@material-ui/icons/Search';
import MembershipTypeItem from './MembershipTypeItem';
import { SuccessToast, Loading } from '../common';
import { get, handleResponse, swal500 } from '../../utils/network';
import config from '../../utils/config';

const styles = theme => ({
    header: {
        padding: "5px 5px 5px 5px",
        paddingLeft: "20px",
        paddingRight: "20px",
        fontSize: "20px",
        color: "#808080"
    },
    searchInput: {
        height: "100%",
        display: "inline-flex",
        flexDirection: "row",
        marginBottom: "6px"
    },
    searchButton: {
        marginTop: "10px"
    },
    bodyContainer: {
        marginTop: "10px"
    },
    loadMoreButton: {
        width: "100%",
        display: "inline-flex",
        marginTop: "30px",
        justifyContent: "center"
    },
    noResults: {
        textAlign: "center",
        fontSize: "20px",
        marginTop: "30px",
        color: theme.palette.secondary.main
    }
});

const MESSAGES = {
    created: 'El Tipo de Abono ha sido creado correctamente',
    edited: 'El Tipo de Abono ha sido editado correctamente',
    deleted: 'El Tipo de Abono ha sido eliminado correctamente'
};

class MembershipTypesContainer extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            successMessage: '',
            loading: true,
            showSeeMore: false,
            nameFilter: '',
            membershipTypes: []
        }
    }

    componentDidMount() {
        this.getMembershipTypes()
        const msgKey = this.props.location.state?.msg;
        if (msgKey) {
            const msg = MESSAGES[msgKey];
            msg && this.setState({ successMessage: msg });
        }

    }

    getFetchUrl = (page) => {
        const queryParams = [`page=${page}`];
        if (this.state.nameFilter !== '') {
            queryParams.push(`name=like:${this.state.nameFilter}`);
        }
        return `membershiptypes?${queryParams.join('&')}`
    }

    addImageToData = (membershipTypes) => membershipTypes.map(m => ({...m, detailImage: config.backEndUrl + "images/membershipType/" + m._id}))

    getMembershipTypes = () => {
        const fetchUrl = this.getFetchUrl(0);
        get(fetchUrl)
            .then(res => handleResponse(res, this.props))
            .then(parsed =>
                this.setState({
                    membershipTypes: this.addImageToData(parsed.message.membershipTypes),
                    membershipTypesCount: parsed.message.count,
                    page: 0,
                    maxPageFetched: 0,
                    loading: false,
                    updating: false,
                    showSeeMore: parsed.message.count > parsed.message.membershipTypes.length
                })
            )
            .catch(err => {
                this.setState({ loading: false, updating: false });
                swal500(err);
            });
    };

    showMore = (page) => {
        this.setState(
            { updating: true },
            () => {
                const fetchUrl = this.getFetchUrl(page);
                get(fetchUrl)
                    .then(res => handleResponse(res, this.props))
                    .then(parsed =>
                        this.setState(prevState => ({
                            membershipTypes: prevState.membershipTypes.concat(this.addImageToData(parsed.message.membershipTypes)),
                            maxPageFetched: page,
                            loading: false,
                            updating: false,
                            showSeeMore: (prevState.membershipTypes.length + parsed.message.membershipTypes.length) < prevState.membershipTypesCount
                        }))
                    )
                    .catch(err => {
                        this.setState({ loading: false, updating: false });
                        swal500(err);
                    });
            }
        )
    };

    handleFilter = () => {
        this.getMembershipTypes();
    }

    onQuestionSearchChange = ({ target }) => {
        this.setState({ nameFilter: target.value });
    }

    onCloseToast = (toastKey) => () => this.setState({ [toastKey]: '' });

    handleOpenForm = (_id, name, detailImage) => _id ? this.props.history.push({
        pathname: '/home/membershipTypes/' + _id,
        state: { _id, name, detailImage },
    })
        :
        this.props.history.push('/home/membershipTypes/new');

    keyPress = (e) => {
        if (e.keyCode === 13)
            this.handleFilter()

    }

    render() {
        const { classes } = this.props;
        return (
            this.state.loading ?
                <Loading />
                :
                <div>
                    <Card elevation={3} className={classes.header}>
                        <Grid
                            container
                            direction="row"
                            alignItems="center"
                            justify="space-between"
                        >
                            <div>
                                <Grid
                                    container
                                    direction="row"
                                    alignItems="center"
                                >
                                    <div style={{ marginRight: "4em" }}>
                                        <h2>Tipos de Abono</h2>
                                    </div>
                                    <div>
                                        <div className={classes.searchInput} >
                                            <TextField
                                                label="Buscar por nombre"
                                                value={this.state.nameFilter}
                                                onKeyDown={this.keyPress}
                                                onChange={this.onQuestionSearchChange}
                                            />
                                            <div className={classes.searchButton}>
                                                <IconButton onClick={this.handleFilter} color="secondary">
                                                    <SearchIcon />
                                                </IconButton>
                                            </div>
                                        </div>
                                    </div>
                                </Grid>
                            </div>
                            <Fab
                                color="primary"
                                size="medium"
                                onClick={() => this.handleOpenForm()}
                            ><AddIcon /></Fab>
                        </Grid>
                    </Card>

                    {
                        (this.state.membershipTypes.length > 0) ?
                            <Grid
                                container
                                justify="space-between"
                                className={classes.bodyContainer}
                            >
                                {this.state.membershipTypes.map(membershipType => (
                                    <MembershipTypeItem _id={membershipType._id} name={membershipType.name} detailImage={membershipType.detailImage} onClick={this.handleOpenForm} />
                                ))}
                            </Grid>

                            :

                            <p className={classes.noResults}>No hay Preguntas Frecuentes</p>
                    }

                    {
                        this.state.showSeeMore ?
                            <div className={classes.loadMoreButton}>
                                <Button startIcon={<AddIcon />} variant="contained" color="primary" onClick={() => this.showMore(this.state.maxPageFetched + 1)}>Cargar más</Button>
                            </div>
                            :
                            null
                    }


                    <SuccessToast
                        open={this.state.successMessage !== ''}
                        onClose={this.onCloseToast('successMessage')}
                        text={this.state.successMessage}
                    />
                </div >
        );
    }
}

export default withTheme(withStyles(styles)(MembershipTypesContainer))