import React from "react";
import {
    CircularProgress,
    Grid,
    Typography,
    FormControlLabel,
    Checkbox,
    Button,
    withStyles
} from "@material-ui/core";
import { Person as PersonIcon } from '@material-ui/icons';
import styles from './styles';
import PasswordResetDialog from "../PasswordReset/PasswordResetDialog";
import { SuccessToast, CustomInput, CustomPassword } from "../../components/common";

const LoginComponent = ({
    classes,
    data,
    handleChange,
    handleSubmit,
    successMessage,
    passwordResetOpen,
    handlePasswordReset,
    passwordResetSuccess,
    onCloseToast
}) => (
    <div className={classes.background}>
        {
            data.loading &&
            <div className={classes.loadingContainer}>
                <CircularProgress />
            </div>
        }
        <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justify="center"
            className={classes.gridLogin}
        >
            <form className={classes.formLogin} onSubmit={handleSubmit}>
                <img
                    src={`/assets/${data.club.crestImagePath}`}
                    width={140}
                    height={130}
                    alt="Logo"
                    style={{ margin: '0 auto' }}
                />
                <Typography
                    component="h1"
                    variant="h5"
                    className={classes.title}
                >
                    Inicio de sesión
                </Typography>
                <div className={classes.inputLogin}>
                    <CustomInput
                        error={data.erroremail}
                        name="email"
                        label="Correo electrónico"
                        type="text"
                        handleChange={handleChange}
                        value={data.email}
                        icon={<PersonIcon className={classes.inputIcon} />}
                    />
                </div>
                <div className={classes.inputLogin}>
                    <CustomPassword
                        error={data.errorpassword}
                        name="password"
                        placeholder="Contraseña"
                        handleChange={handleChange}
                        value={data.password}
                        variant="outlined"
                    />
                </div>
                <div className={classes.rememberContainer}>
                    <FormControlLabel
                        control={<Checkbox value="remember" color="primary" />}
                        label="Recordarme"
                    />
                    <p onClick={() => handlePasswordReset(true)} className={classes.forgetPassword}>
                        Olvidé mi contraseña
                    </p>
                </div>
                <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    className={classes.signInButton}
                    onClick={handleSubmit}
                >
                    Iniciar sesión
                </Button>
            </form>
        </Grid>
        <PasswordResetDialog
            open={passwordResetOpen}
            onClose={() => handlePasswordReset(false)}
            onSubmit={passwordResetSuccess}
        />
        <SuccessToast
            open={successMessage !== ''}
            onClose={onCloseToast('successMessage')}
            text={successMessage}
        />
    </div>
);

export default withStyles(styles)(LoginComponent);