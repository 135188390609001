import React, { useState, useEffect } from 'react';
import './App.css';
import { MuiThemeProvider, createMuiTheme, responsiveFontSizes } from '@material-ui/core/styles';
import Routes from './routes';
import { Provider } from 'react-redux';
import store from './utils/state/store';
import { useSelector } from 'react-redux';

const INITIAL_THEME = {
    palette: {
        secondary: {
            main: '#326273'
        },
        primary: {
            main: '#4eaaf3',
            contrastText: '#FFFFFF'
        },
        state: {
            success: "#1B9F20",
            failure: "#FF0000"
        },
        clubstack: {
            main: '#4eaaf3'
        }
    },
    typography: {
        // Use the system font instead of the default Roboto font.
        fontFamily: ['"Roboto"', 'sans-serif'].join(','),
        useNextVariants: true
    }
};

let initialMuiTheme = createMuiTheme(INITIAL_THEME);

initialMuiTheme = responsiveFontSizes(initialMuiTheme);

function ThemeProvider(){
    const club = useSelector((state) => state);
    const [muiTheme, setMuiTheme] = useState(initialMuiTheme);
    useEffect(() => {
        if (club) {
            const { primaryColor, secondaryColor } = club;
            let theme = { ...INITIAL_THEME };
            primaryColor && (theme.palette.primary.main = primaryColor);
            secondaryColor && (theme.palette.secondary.main = secondaryColor);
            theme = createMuiTheme(theme);
            setMuiTheme(responsiveFontSizes(theme));
        }

    }, [club]);

    return (
        <MuiThemeProvider theme={muiTheme}>
            <Routes />
        </MuiThemeProvider>
    );
}

function App() {
    return (
        <Provider store={store}>
            <ThemeProvider/>
        </Provider>
    );
}

export default App;
