import React, { useState, useEffect } from 'react';
import { handleResponse, post, patch, swal500, del } from '../../../utils/network';
import { useFormValidation } from "../../common";
import validateTournament from './validator';
import TournamentFormComponent from "./TournamentFormComponent";
import * as Swal from "sweetalert2";

export const FIELDS = {
    name: "Nombre",
    hasFinished: "Terminado",
    statistics: "Estadísticas",
    position: "Posición",
    numberOfTeams: "Cantidad de equipos",
    points: "Puntos",
    pointsToFirst: "Puntos hasta el primero",
    pointsToLast: "Puntos hasta el último",
    url: "URL"
};

const INITIAL_STATE = {
    _id: '',
    name: '',
    url: '',
    hasFinished: false,
    sportTeam: '',
    position: 1,
    numberOfTeams: 2,
    points: 0,
    pointsToFirst: 0,
    pointsToLast: 0
};

const TournamentFormContainer = (props) => {
    const [data, setData] = useState({});
    const [editMode, setEditMode] = useState(props.location.editMode || false);
    const [loading, setLoading] = useState(true);
    const [submitting, setSubmitting] = useState(false);

    const {
        handleSubmit,
        handleChange,
        handleBlur,
        handleCheckBox,
        values,
        errors,
        setErrors,
        resetValues,
        mergeErrors,
        runValidations,
        handleChanges
    } = useFormValidation(INITIAL_STATE, values => validateTournament(values));
    const isNewTournament = Object.entries(data).length === 0;

    const getChangedValues = values => {
        const changedValues = [];
        Object.keys(values).forEach(key => {
            data[key] !== values[key] && changedValues.push(key)
        });

        return changedValues.filter(v => v !== 'created');
    };

    const handle400 = err => {
        err.text().then(error => {
            let errorObject = JSON.parse(error);
            let errors = {};
            errorObject.fields.forEach(field => {
                if (field.name === "statistics") {
                    errors["statistics"] = field.message;
                    return;
                }
                const regex = new RegExp(field.name);
                errors[field.name] = field.message.replace(regex, FIELDS[field.name]);
            });
            setErrors(errors);
        });
        return Promise.reject(400);
    };

    const handleClose = () => props.history.push(`/home/sportteams/${props.match.params.sportTeamId}/tournaments`);

    useEffect(() => {
        if (props.match.params.id === 'new') {
            setEditMode(true);
            setLoading(false);
            return;
        } else if (props.location.state) {

            const stateKeys = Object.keys(INITIAL_STATE);

            const { state } = props.location;
            const { statistics, ...tournamentData } = state;
            const formattedTournament = { ...statistics, ...tournamentData };

            const validKeys = {}

            //copy only valid fields
            stateKeys.forEach(k => {
                if (formattedTournament.hasOwnProperty(k))
                    validKeys[k] = formattedTournament[k]
            });
            if (Object.keys(validKeys).length === stateKeys.length) {

                setData(validKeys)
                resetValues(validKeys);
                setLoading(false);
                return;
            }
        }
        handleClose();
    }, [props, resetValues]);


    const handleDelete = () => {
        Swal.fire({
            title: "Eliminar Torneo",
            text: `¿Estás seguro de que querés eliminar el torneo '${values.name}'?`,
            icon: 'warning',
            showCancelButton: true,
            cancelButtonColor: '#4eaaf3', //TODO: theme.palette.primary.main
            confirmButtonColor: "#FF0000", //TODO: theme.palette.state.failure
            cancelButtonText: 'No, conservar',
            confirmButtonText: 'Si, estoy seguro',
            showLoaderOnConfirm: true,
            preConfirm: () => {
                Swal.update({ showCancelButton: false })
                return del(`tournaments/${props.match.params.id}`)
                    .then(res => handleResponse(res, props))
                    .then(result =>
                        props.history.push({
                            pathname: `/home/sportteams/${props.match.params.sportTeamId}/tournaments`,
                            state: { msg: 'deleted' },
                        })
                    )
                    .catch(err => {
                        swal500(err);
                    });
            }
        });
    };

    const handleResult = (e) => {

        const formValid = handleSubmit(e);
        if (formValid) {
            setSubmitting(true);
            if (isNewTournament) {

                const { _id, sportTeam, name, url, hasFinished, ...statistics } = values

                post('tournaments', { sportTeam: props.match.params.sportTeamId, name, ...(url && { url }), hasFinished, statistics })
                    .then(res => handleResponse(res, props, [{ status: 400, method: handle400 }]))
                    .then(res => {
                        setSubmitting(false);
                        props.history.push({
                            pathname: `/home/sportteams/${props.match.params.sportTeamId}/tournaments`,
                            state: { msg: 'created' },
                        });
                    })
                    .catch(err => {
                        setSubmitting(false);
                        if (err !== 400) {
                            swal500(err);
                        }
                    });
            } else {
                let changedValues = getChangedValues(values);
                if (changedValues.length === 0) {
                    props.history.push(`/home/sportteams/${props.match.params.sportTeamId}/tournaments`);
                    return;
                }

                const body = {}
                const statisticsFields = ["position", "numberOfTeams", "points", "pointsToFirst", "pointsToLast"];
                if (changedValues.some(v => statisticsFields.includes(v))) {
                    changedValues = changedValues.filter(v => !statisticsFields.includes(v));
                    let statistics = {}
                    statisticsFields.forEach(f => statistics[f] = values[f]);
                    body.statistics = statistics;
                }
                changedValues.forEach(value => {
                    Object.assign(body, { [value]: values[value] });
                });

                patch(`tournaments/${data._id}`, body)
                    .then(res => handleResponse(res, props, [{ status: 400, method: handle400 }]))
                    .then(res => {
                        setSubmitting(false);
                        props.history.push({
                            pathname: `/home/sportteams/${props.match.params.sportTeamId}/tournaments`,
                            state: { msg: 'edited' },
                        });
                    })
                    .catch(err => {
                        setSubmitting(false);
                        if (err !== 400) {
                            swal500(err);
                        }
                    });
            }
        }
    };

    return (
        <TournamentFormComponent
            errors={errors}
            handleChange={handleChange}
            handleBlur={handleBlur}
            handleClose={handleClose}
            handleCheckBox={handleCheckBox}
            handleResult={handleResult}
            handleDelete={handleDelete}
            loading={loading}
            submitting={submitting}
            values={values}
            editMode={editMode}
            setEditMode={setEditMode}
            isNewTournament={isNewTournament}
            mergeErrors={mergeErrors}
            runValidations={runValidations}
            handleChanges={handleChanges}
        />
    );
};

export default TournamentFormContainer;