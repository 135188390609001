import React from 'react'
import {Card, TextField, Grid, Fab, IconButton, Button } from '@material-ui/core'
import { withTheme, withStyles } from '@material-ui/core/styles'
import AddIcon from '@material-ui/icons/Add';
import SearchIcon from '@material-ui/icons/Search';
import TopicItem from './TopicItem';
import { SuccessToast, Loading } from '../common';
import { get, handleResponse, swal500 } from '../../utils/network';

const styles = theme => ({
    header: {
        padding: "5px 5px 5px 5px",
        paddingLeft: "20px",
        paddingRight: "20px",
        fontSize: "20px",
        color: "#808080"
    },
    searchInput: {
        height:"100%",
        flex:1,
        marginTop:"-15px",
        marginLeft: "80px",
        display:"inline-flex",
        flexDirection: "row"
    },
    searchButton:{
        marginTop:"10px"
    },
    bodyContainer: {
        marginTop: "10px"
    },
    loadMoreButton: {
        width:"100%",
        display: "inline-flex",
        marginTop: "30px",
        justifyContent: "center"
    },
    noResults: {
        textAlign:"center",
        fontSize: "20px",
        marginTop: "30px",
        color: theme.palette.secondary.main
    }
});

const MESSAGES = {
    created: 'El Tópico ha sido creado correctamente',
    edited: 'El Tópico ha sido editado correctamente',
    deleted: 'El Tópico ha sido eliminado correctamente'
};

class TopicsContainer extends React.Component
{

    constructor(props)
    {
        super(props)
        this.state = {
            successMessage: '',
            loading: true,
            showSeeMore: false,
            titleFilter: '',
            topics: []
        }
    }

    componentDidMount()
    {
        this.getTopics()
        const msgKey = this.props.location.state?.msg;
        if (msgKey) {
            const msg = MESSAGES[msgKey];
            msg && this.setState({ successMessage: msg });
        }
        
    }

    getTopics = () => {
        let fetchUrl = "topics?page=0"

        if(this.state.titleFilter)
            fetchUrl += "&title=like:" + this.state.titleFilter

        get(fetchUrl)
            .then(res => handleResponse(res, this.props))
            .then(parsed =>
                this.setState({
                    topics: parsed.message.data,
                    topicsCount: parsed.message.count,
                    page: 0,
                    maxPageFetched: 0,
                    loading: false,
                    updating: false,
                    showSeeMore: parsed.message.count > parsed.message.data.length
                })
            )
            .catch(err => {
                this.setState({ loading: false, updating: false });
                swal500(err);
            });
    };

    showMore = (page) => {
        this.setState(
            { updating: true },
            () => {
                let fetchUrl = 'topics?page=' + page

                if(this.state.titleFilter)
                    fetchUrl += "&title=like:" + this.state.titleFilter

                get(fetchUrl)
                    .then(res => handleResponse(res, this.props))
                    .then(parsed =>
                        this.setState(prevState => ({
                            topics: prevState.topics.concat(parsed.message.data),
                            maxPageFetched: page,
                            loading: false,
                            updating: false,
                            showSeeMore: parsed.message.data.length >= 10
                        }))
                    )
                    .catch(err => {
                        this.setState({ loading: false, updating: false });
                        swal500(err);
                    });
            }
        )
    };

    handleFilter = () => {
        this.getTopics();
    }

    onNameSearchChange = ({target}) => {
        this.setState({titleFilter: target.value});
    }

    onCloseToast = (toastKey) => () => this.setState({ [toastKey]: '' });

    handleOpenForm = (id, title, color) => id ? this.props.history.push({
                                    pathname: '/home/topics/' + id,
                                    state: { id, title, color },
                                }) 
                                    : 
                                this.props.history.push('/home/topics/new');

    keyPress = (e) => {
        if(e.keyCode === 13)
            this.handleFilter()
        
    }

    render()
    {
        const { classes } = this.props;
        return (
            this.state.loading ? 
            <Loading /> 
                : 
            <div>
                <Card elevation={3} className={classes.header}>
                    <Grid
                        container
                        direction="row"
                        alignItems="center"
                        justify="space-between"
                    >
                        <div>
                            <Grid
                                container
                                direction="row"
                                alignItems="center"
                            >
                                <div>
                                <h2>Tópicos</h2>
                                </div>
                                <div className={classes.searchInput} >
                                    <TextField 
                                        label="Buscar por nombre"
                                        value={this.state.titleFilter}
                                        onKeyDown={this.keyPress}
                                        onChange={this.onNameSearchChange}
                                    />
                                    <div  className={classes.searchButton}>
                                        <IconButton onClick={this.handleFilter} color="secondary">
                                            <SearchIcon />
                                        </IconButton>
                                    </div>
                                </div>

                            </Grid>
                        </div>

                        <Fab
                            color="primary"
                            size="medium"
                            onClick={() => this.handleOpenForm()}
                        ><AddIcon /></Fab>
                    </Grid>
                </Card>

                {(this.state.topics.length > 0) ? 
                    <Grid 
                        container
                        justify="space-between"
                        className={classes.bodyContainer}
                    >
                        {this.state.topics.map(topic => (
                            <TopicItem key={topic._id} id={topic._id} color={topic.color} title={topic.title} onClick={this.handleOpenForm} />
                        ))}
                    </Grid>

                    :

                    <p className={classes.noResults}>No hay Tópicos</p>
                }
                
                {this.state.showSeeMore ?
                    <div className={classes.loadMoreButton}>
                        <Button startIcon={<AddIcon />} variant="contained" color="primary" onClick={() => this.showMore(this.state.maxPageFetched + 1)}>Cargar más</Button>
                    </div> 
                    : 
                    null
                }
                

                <SuccessToast
                    open={this.state.successMessage !== ''}
                    onClose={this.onCloseToast('successMessage')}
                    text={this.state.successMessage}
                />
            </div>
        );
    }
}

export default withTheme(withStyles(styles)(TopicsContainer))