export default () => ({
  topActionsContainer: {
    display: 'flex',
    justifyContent: 'space-around',
    alignSelf: 'center',
    marginRight: 15
  },
  textField: {
    marginTop: 13
  },
  comboBox: {
    marginTop: 13
  },
  divider: {
    height: 20
  }
});
