export default [
    {
        id: 'title',
        label: 'Título',
        minWidth: 100,
    },
    {
        id: 'createDate',
        label: 'Fecha de creación',
        minWidth: 100
    },
    {
        id: 'readCount',
        label: 'Lecturas',
        minWidth: 100
    },
    {
        id: 'important',
        minWidth: 20
    }
];