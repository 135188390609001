import React from "react";
import { IconButton, TextField, withStyles, Fab } from "@material-ui/core";
import { Create as CreateIcon, Delete as DeleteIcon } from '@material-ui/icons';
import styles from "./styles";
import { FIELDS } from "./TopicsFormContainer";
import { FormLayout } from "../../common";
import { SliderPicker } from 'react-color';


const TopicsFormComponent = (props) => {
    const {
        classes,
        errors,
        handleChange,
        handleBlur,
        handleClose,
        handleResult,
        handleDelete,
        submitting,
        loading,
        values,
        editMode,
        setEditMode,
        isNewTopic,
    } = props;

    const renderActions = () => (
        <div className={classes.topActionsContainer}>
            {!editMode && (
                <IconButton onClick={() => setEditMode(true)}>
                    <CreateIcon />
                </IconButton>
            )}
            {!isNewTopic &&
                <IconButton onClick={handleDelete}>
                    <DeleteIcon />
                </IconButton>
            }
        </div>
    );

    const onColorChange = (color) => {
        handleChange({
            target: {
                name: "color",
                value: color.hex
            }
        })
    }

    return (
        <FormLayout
            handleClose={handleClose}
            renderActions={renderActions}
            loading={loading}
            editMode={editMode}
            submitting={submitting}
            handleSubmit={handleResult}
            isNew={isNewTopic}
            dataKey="Tópico"
        >
            <TextField
                name='title'
                error={errors.title !== undefined}
                helperText={errors.title}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.title}
                label={FIELDS['title']}
                className={classes.textField}
                InputProps={{
                    readOnly: !editMode,
                    disableUnderline: !editMode
                }}
            />
            {editMode && <div className={classes.divider} />}
            <div className={classes.colorChooser}>
                <Fab disabled size="small" style={{backgroundColor:values.color}} className={classes.FAB} />
                <TextField
                    name='color'
                    error={errors.color !== undefined}
                    helperText={errors.color}
                    // onFocus={openColorPicker}
                    onBlur={handleBlur}
                    value={values.color}
                    label={FIELDS['color']}
                    className={classes.textField}
                    InputProps={{
                        readOnly: true,
                        disableUnderline: !editMode
                    }}
                />
                {editMode ? 
                    <div className={classes.colorPicker}>
                        <SliderPicker 
                            color={values.color}
                            onChangeComplete={onColorChange}
                        />
                    </div>
                : null}
            </div>
        </FormLayout>
    );
};

export default withStyles(styles)(TopicsFormComponent);