import React from 'react'
import { Card, CardContent, Typography } from '@material-ui/core'
import { withTheme, withStyles } from '@material-ui/core/styles'


const styles = theme => ({
    card: {
        width: "300px",
        marginTop: "30px",
    }
});

class FAQItem extends React.Component {
    onClick = () => {
        this.props.onClick(this.props.id, this.props.question, this.props.answer, this.props.author)
    }

    render() {
        const { classes } = this.props;
        return (
            <Card className={classes.card} elevation={3} onClick={this.onClick}>
                <CardContent>
                    <Typography variant="h6" color="textSecondary">
                        {this.props.question}
                    </Typography>
                    <Typography variant="body1" color="textSecondary" align="justify"   >
                        {this.props.answer}
                    </Typography>
                    <Typography variant="caption" color="textSecondary">
                        por {this.props.author.firstName} {this.props.author.lastName}
                    </Typography>
                </CardContent>
            </Card>
        );
    }
}

export default withTheme(withStyles(styles)(FAQItem))