export default () => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'stretch'
    },
    container: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between'
    },
    formHeader: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '10px 5px 0',
    },
    form: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'stretch',
        justifyContent: 'space-evenly',
        padding: '0px 24px 10px 24px',
    },
    actionsContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        marginTop: 30,
        paddingBottom: 10
    },
    titleContainer: {
        display: 'flex',
        alignItems: 'center'
    }
});
