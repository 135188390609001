export default [
    {
        id: 'name',
        label: 'Nombre',
        minWidth: 100,
        hidden: {
            xsDown: true
        }
    },
    {
        id: 'discipline',
        label: 'Disciplina',
        minWidth: 100,
        hidden: {
            xsDown: true
        }
    }
];
