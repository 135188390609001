import React from "react";
import { IconButton, TextField, withStyles } from "@material-ui/core";
import { Create as CreateIcon, Delete as DeleteIcon } from '@material-ui/icons';
import styles from "./styles";
import { FIELDS } from "./MatchFormContainer";
import { FormLayout } from "../../common";
import moment from 'moment';
import 'moment/locale/es';

const MatchFormComponent = (props) => {
    const {
        classes,
        errors,
        handleChange,
        handleBlur,
        handleClose,
        handleResult,
        handleDelete,
        handleDateChange,
        submitting,
        loading,
        values,
        editMode,
        setEditMode,
        isNewMatch,
    } = props;

    const renderActions = () => (
        <div className={classes.topActionsContainer}>
            {!editMode && (
                <IconButton onClick={() => setEditMode(true)}>
                    <CreateIcon />
                </IconButton>
            )}
            {!isNewMatch &&
                <IconButton onClick={handleDelete}>
                    <DeleteIcon />
                </IconButton>
            }
        </div>
    );

    return (
        <FormLayout
            handleClose={handleClose}
            renderActions={renderActions}
            loading={loading}
            editMode={editMode}
            submitting={submitting}
            handleSubmit={handleResult}
            isNew={isNewMatch}
            dataKey="Partido"
        >
            <TextField
                name="date"
                label="Fecha"
                type="datetime-local"
                disabled={!editMode}
                error={errors.date !== undefined}
                helperText={errors.date}
                value={moment(values.date).format('YYYY-MM-DDTHH:mm')}
                onChange={handleDateChange}
                InputLabelProps={{
                    shrink: true,
                }}
            />
            {editMode && <div className={classes.divider} />}
            <TextField
                name='instance'
                error={errors.instance !== undefined}
                helperText={errors.instance}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.instance}
                label={FIELDS['instance']}
                className={classes.textField}
                InputProps={{
                    readOnly: !editMode,
                    disableUnderline: !editMode
                }}
            />
            {editMode && <div className={classes.divider} />}
            <TextField
                name='rivalName'
                error={errors.rivalName !== undefined}
                helperText={errors.rivalName}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.rivalName}
                label={FIELDS['rivalName']}
                className={classes.textField}
                InputProps={{
                    readOnly: !editMode,
                    disableUnderline: !editMode
                }}
            />
            {editMode && <div className={classes.divider} />}
            <TextField
                name='score'
                error={errors.score !== undefined}
                helperText={errors.score}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.score}
                label={FIELDS['score']}
                className={classes.textField}
                InputProps={{
                    readOnly: !editMode,
                    disableUnderline: !editMode
                }}
            />
        </FormLayout>
    );
};

export default withStyles(styles)(MatchFormComponent);