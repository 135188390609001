import config from './config'
import {
    sleep,
    deleteCookie
} from './utils'
import Swal from 'sweetalert2';

const TIMEOUT = 10000; //10 second timeout

export const defaultHeaders = {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': true
};

const doFetch = (path, body, method, useAuthentication, headers, isForm) => {

    let options = {
        method: method,
        headers,
        credentials: 'include'
    };

    if (!isForm && body) {
        options.body = JSON.stringify(body);
    } else if (body && isForm) {
        options.body = body;
    }

    return Promise.race([sleep(TIMEOUT, onTimeout(path)), fetch(getUrl(path), options)]);
};

export const getUrl = path => config.backEndUrl + path;

export const get = (path, useAuthentication = true, headers = defaultHeaders) =>
    doFetch(path, null, HTTPMethods.GET, useAuthentication, headers);

export const post = (path, body, useAuthentication = true, headers = defaultHeaders, isForm = false) =>
    doFetch(path, body, HTTPMethods.POST, useAuthentication, headers, isForm);

export const patch = (path, body, useAuthentication = true, headers = defaultHeaders, isForm = false) =>
    doFetch(path, body, HTTPMethods.PATCH, useAuthentication, headers, isForm);

export const del = (path, body, useAuthentication = true, headers = defaultHeaders) =>
    doFetch(path, body, HTTPMethods.DELETE, useAuthentication, headers);

export const put = (path, body, useAuthentication = true, headers = defaultHeaders) =>
    doFetch(path, body, HTTPMethods.PUT, useAuthentication, headers);


export const HTTPMethods = {
    POST: 'POST',
    GET: 'GET',
    PATCH: 'PATCH',
    DELETE: 'DELETE',
    PUT: 'PUT'
};

/*
    Usage:

    generateFormData(
        {
            picture: {
                uri : image.uri,
                type: 'image/jpeg'
            }
        }
    )
*/
export function generateFormData(form) {
    let formData = new FormData();

    Object.keys(form).forEach(key => {
        formData.append(key, form[key]);
    });

    return formData;
}

export function handleResponse(res, props, errorHandlers = undefined) {
    const statusCode = res.status;
    if (res.ok) {
        return res.text().then(data => {
            return JSON.parse(data);
        });
    } else if (errorHandlers !== undefined) {
        let functionCode = errorHandlers.find(errorHandler => {
            return errorHandler.status === statusCode;
        });
        if (functionCode !== undefined) {
            return functionCode.method(res);
        }
    }
    if (statusCode === 404) {
        props.history.push('/404');
    } else if (statusCode === 401) {
        deleteCookie('isLogged');
        deleteCookie("routeOptions");
        deleteCookie("userId");
        deleteCookie("name");
        props.history.push('/Login');
    } else if (statusCode === 403) {
        Swal.fire({
            icon: 'error',
            title: 'Oops!',
            text: 'Parece que no tiene permisos para acceder a esta vista.',
            customClass:{
                container: 'my-swal'
            }
        }).then(() => {
            props.history.push('/home');
        });
    }
    return Promise.reject(statusCode);
};

function onTimeout(url) {
    return (_, reject) => reject("Request to " + url + " timeout.");
}

export const swal500 = err => {
    if (err !== 400 && err !== 403 && err !== 404 && err !== 401) {
        Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text:
                'Parece que el servidor no esta funcionando actualmente. Por favor intente nuevamente más tarde',
            showCancelButton: false,
            showConfirmButton: true,
            allowOutsideClick: false,
            customClass:{
                container: 'my-swal'
            }
        });
    }
};
