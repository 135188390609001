export default [
    {
        id: 'instance',
        label: 'Instancia',
        minWidth: 100,
        hidden: {
            xsDown: true
        }
    },
    {
        id: 'score',
        label: 'Resultado',
        minWidth: 100,
        hidden: {
            xsDown: true
        }
    },
    {
        id: 'rivalName',
        label: 'Rival',
        minWidth: 100,
        hidden: {
            xsDown: true
        }
    },
    {
        id: 'dateFormatted',
        label: 'Fecha',
        minWidth: 100,
        hidden: {
            xsDown: true
        }
    }
];
