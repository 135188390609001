import React, { Component } from 'react';
import { withTheme, withStyles, Popover } from '@material-ui/core';
import * as Swal from 'sweetalert2';
import { SuccessToast } from '../../common';
import { handleResponse, get, del, swal500 } from '../../../utils/network';
import { genericOnRangeChange } from '../../../utils/utils';
import { Calendar, momentLocalizer } from 'react-big-calendar'
import "react-big-calendar/lib/css/react-big-calendar.css";
import moment from 'moment';
import 'moment/locale/es';
import ReservationsFormContainer from '../ReservationsForm/ReservationsFormContainer';
import { getOpenInterval } from '../utils'
const localizer = momentLocalizer(moment);

const styles = theme => ({
    header: {
        padding: "5px 5px 5px 5px",
        marginBottom: "20px",
        paddingLeft: "20px",
        paddingRight: "20px",
        fontSize: "20px",
        color: "#808080"
    },
    dateInput: {
        height: "100%",
        display: "inline-flex",
        flexDirection: "row"
    },
    searchInput: {
        height: "100%",
        display: "inline-flex",
        flexDirection: "row",
        marginBottom: "6px"
    },
    searchButton: {
        marginTop: "10px"
    },
    bodyContainer: {
        marginTop: "10px"
    },
    textField: {
        marginTop: 13,
        marginLeft: "15px"
    },
    dateField: {
        marginTop: 13,
        marginLeft: "15px",
        marginBottom: "26px"
    },
    loadMoreButton: {
        width: "100%",
        display: "inline-flex",
        marginTop: "30px",
        justifyContent: "center"
    },
    noResults: {
        textAlign: "center",
        fontSize: "20px",
        marginTop: "30px",
        color: theme.palette.secondary.main
    }
});

const MESSAGES = {
    created: 'La reserva ha sido creada correctamente',
    deleted: 'La reserva ha sido eliminada correctamente'
};

class ReservationsContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            facility: null,
            page: 0,
            maxPageFetched: 0,
            editing: false,
            reservations: [],
            reservationsCount: 0,
            loading: true,
            updating: false,
            successMessage: '',
            currentDate: moment(),
            date: moment().toDate(),
            view: 'month',
            anchorEl: null,
            selectedReservation: {},
            start: null
        }
    }

    componentDidMount() {
        const { state } = this.props.location;
        if (state) {
            this.setState({ facility: state, facilityId: state._id });
        }
        else {
            this.props.history.push('/home/facilities');
        }
        const msgKey = state?.msg;
        if (msgKey) {
            const msg = MESSAGES[msgKey];
            msg && this.setState({ successMessage: msg });
        }
    };

    getFetchUrl = (startDate, endDate) => {
        const queryParams = ['app=bof', `page=${this.state.page}`, `from=${startDate}`, `to=${endDate}`];
        return `facilities/${this.state.facilityId}/reservedShifts?${queryParams.join('&')}`
    }

    getReservations = (start, end) => {
        const fetchUrl = this.getFetchUrl(start, end);
        get(fetchUrl)
            .then(res => handleResponse(res, this.props))
            .then(parsed => {
                this.setState((state) => ({
                    page: state.page + 1,
                    reservations: [...state.reservations, ...parsed.message.data.map((reservation) => ({
                        ...reservation,
                        start: new Date(reservation.start),
                        end: new Date(reservation.end)
                    }))],
                    ...(parsed.message.count && {
                        reservationsCount: parsed.message.count,
                    }),
                    loading: false,
                    updating: false
                }),
                    () => {
                        if (this.state.reservations.length < this.state.reservationsCount) {
                            this.getReservations(start, end)
                        }
                    }
                )
            })
            .catch(err => {
                this.setState({ loading: false, updating: false });
                swal500(err);
            });
    };

    onRangeChange = (start, end) => {
        this.setState({
            page: 0,
            reservations: [],
            reservationsCount: 0
        }, () => this.getReservations(start, end))
    };

    setMessage = (msgKey) => {
        if (msgKey) {
            const msg = MESSAGES[msgKey];
            msg && this.setState({ successMessage: msg });
        }
    }

    onCloseToast = (toastKey) => () => this.setState({ [toastKey]: '' });

    handleOpenForm = id => id ? this.props.history.push('/home/reservations/' + id) : this.props.history.push({
        pathname: '/home/reservations/new',
        state: { facilityId: this.state.facilityId }
    });

    handleDelete = reservationId => {
        this.handleClose();
        const { palette } = this.props.theme;
        Swal.fire({
            title: "Eliminar reserva",
            text: "¿Estas seguro de que queres eliminar la reserva?",
            icon: 'warning',
            showCancelButton: true,
            cancelButtonColor: palette.primary.main,
            confirmButtonColor: palette.state.failure,
            cancelButtonText: 'No, conservar reserva',
            confirmButtonText: 'Si, estoy seguro',
        }).then(result => {
            if (result.value) {
                this.setState(
                    { updating: true },
                    () => {
                        del(`reservations/${reservationId}`)
                            .then(res => handleResponse(res, this.props))
                            .then(result => {

                                this.setState(
                                    (prevState) => ({
                                        reservations: prevState.reservations.filter(reservation => reservation._id !== reservationId),
                                        reservationsCount: prevState.reservationsCount - 1,
                                        updating: false,
                                        successMessage: MESSAGES.deleted
                                    })
                                )
                                this.handleClose();
                            })
                            .catch(err => {
                                swal500(err);
                                this.setState({ updating: false });
                            });
                    }
                );
            }
        })
    };

    onSelectReservation = (reservation, event) => {
        this.setState({
            selectedReservation: reservation,
            anchorEl: {
                left: event.clientX,
                top: event.clientY
            }
        });
    }
    onSelectSlot = (slotInfo) => {
        if (this.state.view === 'month') {
            this.onDrillDown(slotInfo.start, 'day')
        }
        else if (moment().isAfter(slotInfo.start)) {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'No podes hacer una reserva pasada!'
            })
        }
        else if (getOpenInterval(slotInfo.start, this.state.facility))
            this.setState({
                anchorEl: {
                    left: slotInfo.box.x,
                    top: slotInfo.box.y
                },
                selectedReservation: {},
                start: slotInfo.start
            });
        else
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'La instalación no está abierta en ese horario o durante la duración completa del turno!'
            })
    };

    onDrillDown = (date, view, drilldownView) => {
        this.setState({ date, view });
        if (view === 'day')
            genericOnRangeChange(this.onRangeChange)([date]);
    }

    customSlotPropGetter = date => {
        if (!getOpenInterval(date, { ...this.state.facility, shiftDuration: 30 }))
            return {
                className: 'closed-time',
                style: {
                    backgroundColor: '#D3D3D3',
                }
            }
        else return {}
    }

    customDayPropGetter = date => {
        if (moment().isBefore(date)) {
            return {
                className: 'future-day',
                style: {
                    backgroundColor: '#FFFFFF'
                }
            }
        }
    }

    handleClick = (event) => {
        this.setState({ anchorEl: event.currentTarget });
    };

    handleClose = () => {
        this.setState({ anchorEl: null });
    };

    addReservation = (reservation) => {
        this.setState(prevState => ({
            reservations: [...prevState.reservations, reservation],
            reservationsCount: prevState.reservationsCount + 1
        }))
    }

    render() {
        const open = Boolean(this.state.anchorEl);
        const id = open ? 'simple-popover' : undefined;

        return (
            <>
                <Calendar
                    selectable
                    date={this.state.date}
                    onNavigate={(date) => this.setState({ date })}
                    view={this.state.view}
                    onView={(view) => this.setState({ view })}
                    getNow={() => this.state.currentDate.toDate()}
                    localizer={localizer}
                    events={this.state.reservations}
                    titleAccessor={(reservation) => `${reservation.reservor.firstName} ${reservation.reservor.lastName}`}
                    allDayAccessor={() => false}
                    onRangeChange={genericOnRangeChange(this.onRangeChange)}
                    onSelectEvent={this.onSelectReservation}
                    onSelectSlot={this.onSelectSlot}
                    onDrillDown={this.onDrillDown}
                    slotPropGetter={this.customSlotPropGetter}
                    dayPropGetter={this.customDayPropGetter}
                    style={{ height: 800 }}
                />
                <Popover
                    id={id}
                    open={open}
                    anchorPosition={this.state.anchorEl}
                    anchorReference='anchorPosition'
                    onClose={this.handleClose}
                    transformOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                >
                    <ReservationsFormContainer
                        facility={this.state.facility}
                        reservation={this.state.selectedReservation}
                        handleClose={this.handleClose}
                        handleDelete={this.handleDelete}
                        setMessage={this.setMessage}
                        addReservation={this.addReservation}
                        start={this.state.start}

                    />
                </Popover>
                <SuccessToast
                    open={this.state.successMessage !== ''}
                    onClose={this.onCloseToast('successMessage')}
                    text={this.state.successMessage}
                />
            </>
        )
    }
}

export default withTheme(withStyles(styles)(ReservationsContainer));
