import { createMuiTheme, MuiThemeProvider, useTheme } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { get, handleResponse, swal500 } from "../../utils/network";
import { SET_CLUB } from '../../utils/state/store';
import OnboardingComponent from "./OnboardingComponent";
import RedirectOnboardingComponent from './RedirectOnboardingComponent';

/*
    TODO: Esta listita deberia estar en el BE
*/

const clubsDef = {
    ferro: {
        logoPath: '/assets/seed/images/escudo_ferro.png',
        theme: {
            palette: {
                primary: {
                    main: '#006534',
                },
                secondary: {
                    main: '#221E1F'
                }
            },
        }
    },
    harrods: {
        logoPath: '/assets/seed/images/escudo_harrods.png',
        theme: {
            palette: {
                primary: {
                    main: '#0093DD',
                },
                secondary: {
                    main: '#CB2C28'
                }
            },
        }
    },
    get: function (club) { return this.hasOwnProperty(club) ? this[club] : undefined }  
}

const OnboardingContainer = (props) => {
    const [clubs, setClubs] = useState([]);
    const [loading, setLoading] = useState(false);
    const theme = useTheme();
    const [clubSubdomainData, setClubSubdomainData] = useState({});
    const [clubSpecificTheme, setClubSpecificTheme] = useState(theme);
    const dispatch = useDispatch();
    const subdomain = window.location.hostname.split('.')[0];

    const handleSubmit = (club) => {
        dispatch({ type: SET_CLUB, club });
        props.history.push({
            pathname: '/login',
            state: { club }
        });
    };

    useEffect(() => {
        const clubData = clubsDef.get(subdomain);

        if(!!clubData) {
            const clubStyle = createMuiTheme(
                {
                    ...theme,
                    ...clubData.theme
                }
            );
            setClubSpecificTheme(clubStyle)
            setClubSubdomainData(clubData)
            return;
        }

    }, []);

    useEffect(() => {
        let clubsCount, responseClubs = [];
        const getClubs = (page) => {
            setLoading(true);
            get(`onboarding?page=${page}&size=1000`)
                .then(res => handleResponse(res, props))
                .then(parsed => {
                    parsed.message.count && (clubsCount = parsed.message.count);
                    parsed.message.clubs.length && (responseClubs = responseClubs.concat(parsed.message.clubs));
                    setLoading(false);
                    if (responseClubs.length < clubsCount) {
                        getClubs(page + 1);
                    } else {
                        setClubs(responseClubs);
                    }
                })
                .catch(err => {
                    setLoading(false);
                    swal500(err);
                });
        };
        getClubs(0);
    }, []);

    useEffect(() => {
        if(clubs.length === 0) return;

        const club = clubs.find(c => c.shortName === subdomain);
        if(club)
            handleSubmit(club);
    }, [clubs])

    return <MuiThemeProvider theme={clubSpecificTheme}>
        {!!clubSubdomainData.logoPath ? 
        <RedirectOnboardingComponent 
            clubData={clubSubdomainData}
        />
        : 
        <OnboardingComponent
            clubs={clubs}
            handleSubmit={handleSubmit} 
            loading={loading} 
        />}
    </MuiThemeProvider>

};

export default OnboardingContainer;