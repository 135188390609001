export default [
    {
        id: 'name',
        label: 'Nombre',
        minWidth: 100,
        hidden: {
            xsDown: true
        }
    },
    {
        id: 'description',
        label: 'Descripción',
        minWidth: 100,
        hidden: {
            xsDown: true
        }
    },
    {
        id: 'shiftDuration',
        label: 'Duración del Turno (min)',
        minWidth: 50,
        hidden: {
            xsDown: true
        }
    },
];
